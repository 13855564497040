$(document).ready(function () {
  if ($('#event-create-or-edit').length > 0) {
    $('input[name="cancel_event_confirmation"]').val('')
    $('input[name="cancel_event_confirmation_ui"]').val('')

    $("[name='tags[children_age_range][]'").on("ifChecked", function (event) {
      if (this.value != 358) {
        $("input[name='tags[children_age_range][]'][value='358']").iCheck("uncheck")
      } else {
        $("input[name='tags[children_age_range][]'][value!='358']").iCheck("uncheck")
      }
    });


    setNonprofitCountry()
    $('input[name=location_zip_code]').removeClass('hidden')
    $('input[name=ship_to_zip_code]').removeClass('hidden')

    $('input[name=ship_to_zip_code]').val(
      $('input[name=ship_to_zip_code]').attr('value')
    )
    $('input[name=location_zip_code]').val(
      $('input[name=location_zip_code]').attr('value')
    )

    var id = location.pathname.split('/')[2]
    if (id == 'create') {
      var id = 0
    }

    $('.draftLink').click(function () {
      $('#draft').trigger('click')
    })

    $('.deleteLink').click(function () {
      $('#deleteModal').modal('show')
    })

    $('#delete-event').click(function () {
      $('#delete').trigger('click')
    })

    $('#confirmEventCanceled').click(function () {
      $('input[name="cancel_event_confirmation"]').val($('input[name="cancel_event_confirmation_ui"]').val())
      $('#cancel').trigger('click')
    })

    $('#event-begin-time').timepicker({
      timeFormat: 'g:i A',
      scrollDefault: '06:00 PM'
    })

    $('#event-end-time').timepicker({
      timeFormat: 'g:i A'
    })

    tinymce.init({
      selector: '#company-description',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1:
        'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px';
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })

    $('#event-managed-datatables').dataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      order: [[0, 'desc']],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block';
        }
 else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none';
        }
      }
    })

    $('.row-count-pre').html('Show')
    $('.row-count-post').html('per page')

    $('input[name^="future"]')
      .add('input[name^="status"]')
      .add('input[name^="organization_tags"]')
      .on('ifToggled', function () {
        $('#filter').submit()
      })

    $('#save-event-filter').click(function () {
      $('#myModal').modal('hide')

      document.getElementById('action').value = 'save';
      document.getElementById('event-list-filter-save').click()
    })

    $('input[name=on_site_flag]').onoff()

    $('#onoffswitch-inner').addClass('yesno')

    var default_event_begin_date = $('#event-begin-date').val()
    var default_event_end_date = $('#event-end-date').val()
    var default_deadline_date = $('#deadline-date').val()

    $('#event-begin-date').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    $('#event-end-date').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    $('#event-begin-date').focusout(function () {
      $('#event-begin-date').val(
        $('#event-begin-date')
          .val()
          .replace(/\/00/g, '/20')
      )
      if ($('#event-end-date').val() == '') {
        $('#event-end-date').val($('#event-begin-date').val())
      }
    })

    $('#event-end-date').focusout(function () {
      $('#event-end-date').val(
        $('#event-end-date')
          .val()
          .replace(/\/00/g, '/20')
      )
    })

    $('#event-begin-time').focusout(function () {
      if ($('#event-end-time').val() == '') {
        $('#event-end-time').val($('#event-begin-time').val())
      }
    })

    $('#event-begin-date').on('apply.daterangepicker', function () {
      $('#event-begin-date').val(
        $('#event-begin-date')
          .val()
          .replace(/\/00/g, '/20')
      )
      $('#event-end-date').val(
        $('#event-end-date')
          .val()
          .replace(/\/00/g, '/20')
      )
      if ($('#event-end-date').val() == '') {
        $('#event-end-date').val($('#event-begin-date').val())
      }
    })

    if (default_event_begin_date == '') {
      $('#event-begin-date').val('')
    }

    if (default_event_end_date == '') {
      $('#event-end-date').val('')
    }

    $('#deadline-date').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    if (default_deadline_date == '') {
      $('#deadline-date').val('')
    }

    Dropzone.options.eventLogoUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '.logo-fileinput-button',
      method: 'POST',
      url: '/events/'.concat(id, '/upload'),
      previewsContainer: '#logoSection #dropzonePreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('#logoSection .dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
          // Create the remove button
          var removeButton = Dropzone.createElement(
            "<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>"
          )

          // Capture the Dropzone instance as closure.
          var _this = this

          // Listen to the click event
          removeButton.addEventListener('click', function (e) {
            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

            // Remove the file preview.
            _this.removeFile(file)
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          })

          // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      sending: function (file, xhr, formData) {
        formData.append('type', 'logo')
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('#logoSection .dz-default, .dz-message')
          .children('img')
          .attr('src', obj.filename)
        this.removeFile(file)
        $('#event-logo-url').attr('src', obj.filename)
        $('#event-logo-url').css('display', '')
      }
    }

    $('#delete-event-banner').click(function () {
      $('#deleteEventBannerModal').modal('show')
    })

    $('#confirmEventBanner').click(function () {
      deleteEventBannerImage()
    })

    Dropzone.options.eventBannerUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '.banner-fileinput-button',
      method: 'POST',
      url: '/events/'.concat(id, '/upload'),
      previewsContainer: '#bannerSection #dropzoneBannerPreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('#bannerSection .dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
          // Create the remove button
          var removeButton = Dropzone.createElement(
            "<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>"
          )

          // Capture the Dropzone instance as closure.
          var _this = this

          // Listen to the click event
          removeButton.addEventListener('click', function (e) {
            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

            // Remove the file preview.
            _this.removeFile(file)
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          })

          // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      sending: function (file, xhr, formData) {
        formData.append('type', 'banner')
      },
      success: function (file, response) {
        let obj = JSON.parse(response)
        $('#bannerSection .dz-default, .dz-message')
          .children('img')
          .attr('src', obj.filename)
        this.removeFile(file)
        $('#event-banner-url').attr('src', obj.filename)
        $('#event-banner-url').css('display', '')
        $('#delete-event-banner').removeClass('hidden')
        $("[name='banner_id']").val(obj.id)
      }
    }
  }
  if ($('#events-index').length > 0) {
    $('#event-datatables').dataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      columns: [
        { orderable: false },
        null,
        null,
        { orderable: false },
        { orderable: false }
      ],
      order: [[0, 'desc']],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block';
        }
 else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none';
        }
      }
    })

    $('.row-count-pre').html('Show')
    $('.row-count-post').html('per page')
  }

  if ($('#show-event').length > 0) {
    $('#offer-item #offer').click(function () {
      $('input[name=offer]').click()
    })
  }
})

function setNonprofitCountry () {
  var countryStr = $('label[for=city_line]').attr('data-country')

  $('#event-create-or-edit label[for=city_line]').removeClass('hidden')

  if (countryStr == 'usa') {
    $('label[for=city_line]').text('City/State/Zip')
  }
 else if (countryStr == 'canada') {
    $('label[for=city_line]').text('City Province Postal Code')
  }
}

function deleteEventBannerImage () {
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('#token').attr('content')
    }
  })

  $.ajax({
    url: '/images/'.concat(
      $("[name='banner_id']").val() ? $("[name='banner_id']").val() : 'banner'
    ),
    type: 'DELETE',
    success: function (result) {
      $('#event-banner-url').addClass('hidden')
      $('#delete-event-banner').addClass('hidden')
    }
  })
}
