/* eslint-disable */
$(document).ready(function() {
  if ($('.company-create-or-edit').length > 0) {
    tinymce.init({
      theme: 'modern',
      selector: '#email-message',
      menubar: false,
      height: 350,
      setup: function(ed) {
        ed.on('init', function() {
          this.getDoc().body.style.fontSize = '12px'
        })
      }
    })

    function getEmailTemplate(id) {
      var url = window.location.href

      $.getJSON(
        '/admin/emails/merged/'.concat(
          id,
          '/',
          url.replace(/^https?:\/\//, '').split('/')[2]
        ),
        function(data) {
          $('[name=to_email]').val(data.to_email)
          $('[name=to_name]').val(data.to_name)
          $('[name=from_email]').val(data.from_email)
          $('[name=from_name]').val(data.from_name)
          $('[name=subject]').val(data.subject)
          $('[name=message]').val(data.body)
          $('[name=next_email_template_id]').val(data.next_email_template_id)
          tinyMCE.get('email-message').setContent(data.body)
        }
      )
    }

    function sendMessage() {
      var url = window.location.href

      var data = JSON.stringify({
        to_email: $('#sendEmail #send-message-form [name=to_email]').val(),
        to_name: $('#sendEmail #send-message-form [name=to_name]').val(),
        from_email: $('#sendEmail #send-message-form [name=from_email]').val(),
        from_name: $('#sendEmail #send-message-form [name=from_name]').val(),
        subject: $('#sendEmail #send-message-form [name=subject]').val(),
        message: tinyMCE.get('email-message').getContent({ format: 'raw' }),
        messageable_id: url.replace(/^https?:\/\//, '').split('/')[2],
        messageable_type: 'Nonprofit',
        next_email_template_id: $(
          '#sendEmail #send-message-form [name=next_email_template_id]'
        ).val()
      })
      $.ajax({
        url: '/messages',
        dataType: 'json',
        contentType: 'application/json; charset=UTF-8',
        data: data,
        type: 'POST',
        error: function(xhr, ajaxOptions, thrownError) {
          return true
        }
      })
    }

    $('#send-email').validate({
      rules: {
        to_email: {
          required: true,
          email: true
        },
        from_email: {
          required: true,
          email: true
        },
        subject: {
          required: true
        }
      },
      messages: {
        to_email: {
          required: 'Please provide a valid email address'
        },
        from_email: {
          required: 'Please provide a valid email address'
        },
        subject: {
          required: 'Please provide a subject'
        }
      }
    })

    $('[name=to_email]').on('change', function() {
      $('[name=to_email]').val(
        $('[name=to_email]')
          .val()
          .trim()
      )
    })

    $('#send-email').validate({
      rules: {
        to_email: {
          required: true,
          email: true
        },
        from_email: {
          required: true,
          email: true
        },
        subject: {
          required: true
        }
      },
      messages: {
        to_email: {
          required: 'Please provide a valid email address'
        },
        from_email: {
          required: 'Please provide a valid email address'
        },
        subject: {
          required: 'Please provide a subject'
        }
      }
    })

    $('.sendEmailButton').click(function() {
      getEmailTemplate(this.getAttribute('email-id'))
      $('#sendEmail').modal('show')
    })

    $('#send-message').click(function() {
      if ($('#send-email').valid()) {
        var error = sendMessage()
        if (error) {
          event.preventDefault()
          return false
        }
        //               $("#saveAsDraft").trigger('click');
      } else {
        $('#sendEmail').modal('show')
        return false
      }
    })

    $('input[name=zip_code]').val($('input[name=zip_code]').attr('value'))

    $('input.country').on('ifChecked', function() {
      set_company_country(this)
    })

    if ($('input.country[type=radio]').not(':checked').length == 2) {
      $('input.country')
        .first()
        .iCheck('check')
    } else {
      set_company_country($('input.country[type=radio]:checked'))
    }

    var id = location.pathname.split('/')[2]
    if (id == 'create') {
      var id = 0
    }

    $('#approve_company').click(function() {
      $('[name=approve]').trigger('click')
    })

    $('#reviewed_company').click(function() {
      $('[name=reviewed]').trigger('click')
    })

    tinymce.init({
      selector: '#company-description',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1:
        'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function(ed) {
        ed.on('init', function() {
          this.getDoc().body.style.fontSize = '14px'
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })

    Dropzone.options.companyLogoUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '.fileinput-button',
      method: 'POST',
      url: '/companies/'.concat(id, '/upload'),
      previewsContainer: '#dropzonePreview',
      init: function() {
        this.on('error', function(file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function(file) {
          // Create the remove button
          var removeButton = Dropzone.createElement(
            '<button class="btn btn-success" style=\'position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;\'>Remove file</button>'
          )

          // Capture the Dropzone instance as closure.
          var _this = this

          // Listen to the click event
          removeButton.addEventListener('click', function(e) {
            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

            // Remove the file preview.
            _this.removeFile(file)
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          })

          // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function(file, response) {
        obj = JSON.parse(response)
        $('.dz-default, .dz-message')
          .children('img')
          .attr('src', obj.filename)
        this.removeFile(file)
        $('#company-logo-url').attr('src', obj.filename)
        $('#company-logo-url').css('display', '')
      }
    }
  } else if ($('#companies-index-datatables').length > 0) {
    var table = $('#companies-index-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'All']],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      fnDrawCallback: function() {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block'
        } else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none'
        }
      }
    })
  }
})

function set_company_country(country) {
  var countryStr = $(country).attr('data-country')

  $('input[name=zip_code]').removeClass('hidden')
  $('div.country').addClass('hidden')
  $('div.' + $(country).attr('data-country')).removeClass('hidden')
  $('option.' + $(country).attr('data-country')).removeClass('hidden')
  $('input.mask-zipCode').removeClass('usa')
  $('input.mask-zipCode').removeClass('canada')
  $('input.mask-zipCode').addClass($(country).attr('data-country'))
  $('.mask-zipCode.usa').mask('99999')
  $('.mask-zipCode.canada').mask('a9a9a9')
  $('.mask-zipCode.usa').attr('placeholder', '99999')
  $('.mask-zipCode.canada').attr('placeholder', 'A9A9A9')

  if (countryStr == 'usa') {
    $('label[for=city_line]').text('City/State/Zip')
  } else if (countryStr == 'canada') {
    $('label[for=city_line]').text('City Province Postal Code')
  }
}
