$(document).ready(function () {
  if ($('[data-country=canada]:checked').length) {
    $('div.canada').removeClass('hidden')
  }

  if ($('[data-country=usa]:checked').length) {
    $('div.usa').removeClass('hidden')
  }

  $('input[name=location_type]').on('ifChecked', function () {
    $('.country')
      .not('.item')
      .addClass('hidden')
    if ($(this).val() == '1') {
      $('.country').removeClass('hidden')
    }
  })

  if ($('input[name=location_type]').val() == '1') {
    $('.country').removeClass('hidden')
  }
  else {
    $('.country')
      .not('.item')
      .addClass('hidden')
  }

  $('input[name^=countries]').on('ifUnchecked', function () {
    var selected = $('[name^=metro_areas]').val()
    var country = $(this).attr('data-country')
    var newSelection = $('[name^=metro_areas]').val()
    if (country == 'usa') {
      $('div.usa').addClass('hidden')
      $('input[name^=countries][data-country=canada]').iCheck('check')
    }
    if (country == 'canada') {
      $('div.canada').addClass('hidden')
      $('input[name^=countries][data-country=usa]').iCheck('check')
    }
    // Unselect any items selected for the unchecked country

    if (selected) {
      $.each(selected, function (key, selection) {
        if (
          $(
            '[name^=metro_areas] option.' +
              country +
              '[value=' +
              selection +
              ']'
          ).length
        ) {
          i = newSelection.indexOf(selection)
          if (i != -1) {
            newSelection.splice(i, 1)
          }
        }
      })
      $('.metro-area-multiple')
        .val(newSelection)
        .trigger('change')
    }
    $('input.select2-search__field').attr(
      'placeholder',
      getItemMetroAreaPlaceHolder($(this), 'unchecked')
    )
  })

  $('input[name^=countries]').on('ifChecked', function () {
    if ($('.metro-area-multiple').val()) {
      var country = $(this).attr('data-country')

      if (country == 'usa') {
        $('div.usa').removeClass('hidden')
      }

      if (country == 'canada') {
        $('div.canada').removeClass('hidden')
      }

      var usa = 0
      var canada = 0
      if ($('[name^=metro_areas]').val()) {
        $.each($('[name^=metro_areas]').val(), function (key, selection) {
          if (
            $('[name^=metro_areas] option.canada[value=' + selection + ']')
              .length
          ) {
            canada++
          }
          if (
            $('[name^=metro_areas] option.usa[value=' + selection + ']').length
          ) {
            usa++
          }
        })
      }
      var selected = $('[name^=metro_areas]').val()
      if ($(this).attr('data-country') == 'canada' && canada == 0) {
        selected.push($('[name^=metro_areas] option.country.canada').val())
        $('.metro-area-multiple')
          .val(selected)
          .trigger('change')
      }
      if ($(this).attr('data-country') == 'usa' && usa == 0) {
        selected.push($('[name^=metro_areas] option.country.usa').val())
        $('.metro-area-multiple')
          .val(selected)
          .trigger('change')
      }
    }

    $('input.select2-search__field').attr(
      'placeholder',
      getItemMetroAreaPlaceHolder($(this), 'checked')
    )
  })

  $(document).on('click', '.select2-results__options li', function () {
    usa = $('.metro-area-multiple option.country.usa')
    canada = $('.metro-area-multiple option.country.canada')

    if ($(this).text() == usa.text()) {
      var newList = [usa.val()]

      $.each($('.metro-area-multiple').val(), function (index, value) {
        if (
          $('.metro-area-multiple option.canada[value=' + value + ']').length
        ) {
          newList.push(value)
        }
      })
      $('.metro-area-multiple').val(newList)
      $('.metro-area-multiple').trigger('change')
    }
    else if ($(this).text() == canada.text()) {
      var newList = [canada.val()]

      $.each($('.metro-area-multiple').val(), function (index, value) {
        if ($('.metro-area-multiple option.usa[value=' + value + ']').length) {
          newList.push(value)
        }
      })
      $('.metro-area-multiple').val(newList)
      $('.metro-area-multiple').trigger('change')
    }
  })

  $('.metro-area-multiple').on('select2:select', function () {
    usa = $('.metro-area-multiple option.country.usa').val()
    canada = $('.metro-area-multiple option.country.canada').val()

    var array = $('.metro-area-multiple').val()

    var canada_count = 0
    var usa_count = 0

    // get selected count for each country
    $.each($('.metro-area-multiple').val(), function (index, value) {
      if (
        $('.metro-area-multiple option.usa[value=' + value + ']').not(
          '.country'
        ).length
      ) {
        usa_count++
      }
      if (
        $('.metro-area-multiple option.canada[value=' + value + ']').not(
          '.country'
        ).length
      ) {
        canada_count++
      }
    })

    if (usa_count) {
      var index_usa = array.indexOf(usa)
      if (index_usa > -1) {
        array.splice(index_usa, 1)
      }
    }

    if (canada_count) {
      var index_canada = array.indexOf(canada)
      if (index_canada > -1) {
        array.splice(index_canada, 1)
      }
    }

    $('.metro-area-multiple').val(array)
    $('.metro-area-multiple').change()
  })

  $('input.select2-search__field').attr('style', 'width: 690px !important')

  $('.metro-area-multiple').on('select2:open', function () {
    $("li[aria-label='United States']").hide()
    if ($('input[data-country=usa]:checked').length) {
      $("li[aria-label='United States']").show()
    }
    $("li[aria-label='Canada']").hide()
    if ($('input[data-country=canada]:checked').length) {
      $("li[aria-label='Canada']").show()
    }
  })

  $('.metro-area-multiple').on('change', function () {
    if ($('.metro-area-multiple') == null) {
      $('input.select2-search__field').attr(
        'placeholder',
        getItemMetroAreaPlaceHolder($(this), 'unchecked')
      )
    }
  })
})

function getItemMetroAreaPlaceHolder (element, action) {
  if ($('.metro-area-multiple').val() != null) {
    return ''
  }
  var placeHolder = 'All United States Metro Areas'

  var usa_checked = $('input[name ="countries[]"][data-country=usa]:checked')
    .length
  var canada_checked = $(
    'input[name ="countries[]"][data-country=canada]:checked'
  ).length

  $('div.usa').removeClass('hidden')
  $('div.canada').addClass('hidden')

  if (usa_checked && canada_checked) {
    placeHolder = 'All United States and Canada Metro Areas'
    $('div.canada').removeClass('hidden')
    $('div.usa').removeClass('hidden')
  }
  else if (canada_checked) {
    placeHolder = 'All Canadian Metro Areas'
    $('div.canada').removeClass('hidden')
    $('div.usa').addClass('hidden')
  }
  $('input.select2-search__field').attr('style', 'width: 690px !important')
  return placeHolder
}
