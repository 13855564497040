$(document).ready(function () {
  if ($('#dashboard').length > 0) {
    $('#company-children-datatables').append(
      '<tfoot><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th><th></th></tfoot>'
    )

    var table = $('#company-children-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      autoWidth: false,
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      fnDrawCallback: function () {
        if (
          $('#company-children-datatables').DataTable().page.info().pages > 1
        ) {
          $('.dataTables_paginate.paging_simple_numbers').removeClass('hidden')
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers').addClass('hidden')
        }
        var api = this.api()
        var requests = 0
        var totalQuantity = 0
        var totalValue = 0
        var events = 0
        var attendees = 0
        var pendingMatches = 0

        for (var i = 0; i < api.column(7).data().length; i++) {
          requests += Number(api.column(2).data()[i].replace(/,/g, ''))
          totalQuantity += Number(api.column(3).data()[i].replace(/,/g, ''))
          events += Number(api.column(4).data()[i].replace(/,/g, ''))
          attendees += Number(api.column(6).data()[i].replace(/,/g, ''))
          totalValue += Number(
            api.column(7).data()[i].substr(1).replace(/,/g, '')
          )
          pendingMatches += Number(api.column(8).data()[i].replace(/,/g, ''))
        }

        var pennies = totalValue % 1 != 0 ? 2 : 0
        $(api.column(0).footer()).html('Totals')
        $(api.column(1).footer()).html('')
        $(api.column(2).footer()).html(
          Number(requests).formatMoney(0, '.', ',')
        )
        $(api.column(3).footer()).html(
          Number(totalQuantity).formatMoney(0, '.', ',')
        )
        $(api.column(4).footer()).html(Number(events).formatMoney(0, '.', ','))
        $(api.column(5).footer()).html(
          Number(requests ? (events / requests) * 100 : 0).formatMoney(
            2,
            '.',
            ','
          ) + '%'
        )
        $(api.column(6).footer()).html(
          Number(attendees).formatMoney(0, '.', ',')
        )
        $(api.column(7).footer()).html(
          '$' + Number(totalValue).formatMoney(pennies, '.', ',')
        )
        $(api.column(8).footer()).html(
          Number(pendingMatches).formatMoney(0, '.', ',')
        )
      }
    })

    $('select')
      .not('[name=company-children-datatables_length]')
      .on('change', function () {
        $('#click').trigger('click')
      })

    $('#company-location-giveback-datatables').append(
      '<tfoot><th></th><th></th><th></th><th></th><th></th><th></th></tfoot>'
    )

    $('#company-location-giveback-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      autoWidth: false,
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      fnDrawCallback: function () {
        if (
          $('#company-location-giveback-datatables').DataTable().page.info()
            .pages > 1
        ) {
          $('.dataTables_paginate.paging_simple_numbers').removeClass('hidden')
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers').addClass('hidden')
        }
        var api = this.api()
        var totalQuantity = 0
        var totalValue = 0
        var events = 0
        var attendees = 0
        var pendingMatches = 0

        for (var i = 0; i < api.column(4).data().length; i++) {
          totalQuantity += Number(api.column(1).data()[i].replace(/,/g, ''))
          events += Number(api.column(2).data()[i].replace(/,/g, ''))
          attendees += Number(api.column(3).data()[i].replace(/,/g, ''))
          totalValue += Number(
            api.column(4).data()[i].substr(1).replace(/,/g, '')
          )
          pendingMatches += Number(api.column(5).data()[i].replace(/,/g, ''))
        }

        var pennies = totalValue % 1 != 0 ? 2 : 0
        $(api.column(0).footer()).html('Totals')
        $(api.column(1).footer()).html(
          Number(totalQuantity).formatMoney(0, '.', ',')
        )
        $(api.column(2).footer()).html(Number(events).formatMoney(0, '.', ','))
        $(api.column(3).footer()).html(
          Number(attendees).formatMoney(0, '.', ',')
        )
        $(api.column(4).footer()).html(
          '$' + Number(totalValue).formatMoney(pennies, '.', ',')
        )
        $(api.column(5).footer()).html(
          Number(pendingMatches).formatMoney(0, '.', ',')
        )
      }
    })

    $('#company-location-inkind-datatables').append(
      '<tfoot><th></th><th></th><th></th><th></th><th></th><th></th></tfoot>'
    )

    $('#company-location-inkind-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      autoWidth: false,
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      fnDrawCallback: function () {
        if (
          $('#company-location-inkind-datatables').DataTable().page.info()
            .pages > 1
        ) {
          $('.dataTables_paginate.paging_simple_numbers').removeClass('hidden')
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers').addClass('hidden')
        }

        var api = this.api()
        var totalQuantity = 0
        var totalValue = 0
        var events = 0
        var attendees = 0
        var pendingMatches = 0

        for (var i = 0; i < api.column(4).data().length; i++) {
          totalQuantity += Number(api.column(1).data()[i].replace(/,/g, ''))
          events += Number(api.column(2).data()[i].replace(/,/g, ''))
          attendees += Number(api.column(3).data()[i].replace(/,/g, ''))
          totalValue += Number(
            api.column(4).data()[i].substr(1).replace(/,/g, '')
          )
          pendingMatches += Number(api.column(5).data()[i].replace(/,/g, ''))
        }

        var pennies = totalValue % 1 != 0 ? 2 : 0
        $(api.column(0).footer()).html('Totals')
        $(api.column(1).footer()).html(
          Number(totalQuantity).formatMoney(0, '.', ',')
        )
        $(api.column(2).footer()).html(Number(events).formatMoney(0, '.', ','))
        $(api.column(3).footer()).html(
          Number(attendees).formatMoney(0, '.', ',')
        )
        $(api.column(4).footer()).html(
          '$' + Number(totalValue).formatMoney(pennies, '.', ',')
        )
        $(api.column(5).footer()).html(
          Number(pendingMatches).formatMoney(0, '.', ',')
        )
      }
    })

    if ($('#nonprofit-children-datatables').length > 0) {
      var columns = $('#nonprofit-children-datatables').length ? [null, null, null, null, null, null, null] : [null, null, null, null, null, null, null]

      var table = $('#nonprofit-children-datatables').DataTable({
        sDom:
          "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
        stateSave: true,
        fixedHeader: true,
        lengthMenu: [
          [25, 50, 100, -1],
          [25, 50, 100, 'All']
        ],
        language: {
          zeroRecords: 'Sorry, there were no results for the selected criteria',
          infoEmpty: '0 Results',
          infoFiltered: '(filtered from _MAX_ total results)',
          search: '<i class="im-search" style="float: left; padding: 5px;"> </i>',
          lengthMenu: '<span>_MENU_</span>',
          searchPlaceholder: 'Search Within Results',
          info: 'Showing _START_ to _END_ of _TOTAL_ results'
        },
        columns: columns,
        order: [[2, 'asc']],
        fnDrawCallback: function () {
          $('#filter_button').removeClass('hidden')
          $('#filter_spinner').addClass('hidden')
          if ($('.paginate_button.active').length > 0) {
            $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
              'block'
          }
          else {
            $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
              'none'
          }
          update_stats()
        }
      })
    }
  }
})
