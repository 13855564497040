$(document).ready(function () {
  if ($('#event-create-or-edit').length > 0) {
    $('#selectNonprofitModal').modal('show')
  }

  if ($('#nonprofits-index-datatables').length > 0) {
    var table = $('#nonprofits-index-datatables').DataTable({
      'sDom': "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'All']],
      'language': {
        'zeroRecords': 'Sorry, there were no results for the selected criteria',
        'infoEmpty': '0 Results',
        'infoFiltered': '(filtered from _MAX_ total results)',
        'search': '<i class="im-search"> </i>',
        'lengthMenu': '<span>_MENU_</span>',
        'searchPlaceholder': 'Search...',
        'info': 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      'fnDrawCallback': function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display = 'block'
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display = 'none'
        }
      }
    })
  }
  if ($('#nonprofits-edit').length > 0 || $('#nonprofits-create').length > 0) {
    var id = location.pathname.split('/')[2]
    if (id == 'create') {
      var id = 0
    }

            // ------------- Masked input fields -------------//
    $('.mask-phone').mask('(999) 999-9999')
    $('.mask-ein').mask('99-9999999')
    $('.mask-zipCode').mask('99999')

    $('#mask-phoneExt').mask('(999) 999-9999? x99999')
    $('#mask-phoneInt').mask('+40 999 999 999')
    $('#mask-date').mask('99/99/9999')
    $('#mask-ssn').mask('999-99-9999')
    $('#mask-productKey').mask('a*-999-a999', {placeholder: '*'})
    $('#mask-eyeScript').mask('~9.99 ~9.99 999')
    $('#mask-percent').mask('99%')

    $('#is_school').on('ifChecked', function (event) {
      $('#is_school_group').css('display', '')
    })

    $('#is_school').on('ifUnchecked', function (event) {
      $('#is_school_group').css('display', 'none')
    })

    $('#is_school').on('ifUnchecked', function (event) {
      $('#is_school_group').css('display', 'none')
    })

    if ($('#is_school').is(':checked')) {
      $('#is_school_group').css('display', '')
    }
    else {
      $('#is_school_group').css('display', 'none')
    }

    tinymce.init({
      selector: '#nonprofit-description',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1: 'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px'
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })

    $('#role').on('change', function (event) {
      switch ($('#role option:selected').val()) {
        case 'staff' :
          $('#contact_email').css('display', 'none')
          $('#third-party-fundraiser').css('display', 'none')
          $('#volunteer').css('display', 'none')
          $('#fundraising_url').css('display', 'none')
          break

        case 'volunteer' :
          $('#third-party-fundraiser').css('display', 'none')
          $('#volunteer').css('display', 'block')
          $('#contact_email').css('display', '')
          $('#fundraising_url').css('display', 'none')
          break

        case 'third-party' :
          $('#third-party-fundraiser').css('display', '')
          $('#volunteer').css('display', 'none')
          $('#contact_email').css('display', '')
          $('#fundraising_url').css('display', '')
          break
      }
    })

    switch ($('#role option:selected').val()) {
      case 'staff' :
        $('#contact_email').css('display', 'none')
        $('#third-party-fundraiser').css('display', 'none')
        $('#fundraising_url').css('display', 'none')
        break

      case 'volunteer' :
        $('#third-party-fundraiser').css('display', 'none')
        $('#contact_email').css('display', '')
        $('#fundraising_url').css('display', 'none')
        break

      case 'third-party' :
        $('#third-party-fundraiser').css('display', '')
        $('#contact_email').css('display', '')
        $('#fundraising_url').css('display', '')
        break
    }

    $('#is_third_party_fundraiser').on('ifChecked', function (event) {
      $('#third-party-fundraiser').css('display', '')
    })
  }

  if ($('.nonprofits_edit').length || $('#nonprofits-create').length) {
        // Odd - on reload (with errors) the value isn't being set, but the attribute is
    $('input[name=zip_code]').val($('input[name=zip_code]').attr('value'))

    $('input.country').on('ifChecked', function () {
      set_nonprofit_country(this)
    })

    if ($('input.country[type=radio]').not(':checked').length == 2) {
      $('input.country').first().iCheck('check')
    }
    else {
      set_nonprofit_country($('input.country[type=radio]:checked'))
    }

    function set_nonprofit_country (country) {
      var metro = $('select#metro_area').val()
      var countryStr = $(country).attr('data-country')

      $('input[name=zip_code]').removeClass('hidden')
      $('div.country').addClass('hidden')
      $('div.' + $(country).attr('data-country')).removeClass('hidden')
      $('option.' + $(country).attr('data-country')).removeClass('hidden')
      $('input.mask-zipCode').removeClass('usa')
      $('input.mask-zipCode').removeClass('canada')
      $('input.mask-zipCode').addClass($(country).attr('data-country'))
      $('.mask-zipCode.usa').mask('99999')
      $('.mask-zipCode.canada').mask('a9a9a9')
      $('.mask-zipCode.usa').attr('placeholder', '99999')
      $('.mask-zipCode.canada').attr('placeholder', 'A9A9A9')

      if (countryStr == 'canada') {
        $('option.country.usa').toggleOption(false)
      }
      else if (countryStr == 'usa') {
        $('option.country.canada').toggleOption(false)
      }
      $('option.' + $(country).attr('data-country')).toggleOption(true)
      if (metro == null || $("select#metro_area option[value='" + metro + "']").css('display') == 'none') {
        if ($("option.country[style*='inline']").length) {
          $('select#metro_area').val($("option.country[style*='inline']").first().val()) // Safari
        }
        if ($("option.country[style*='block']").length) {
          $('select#metro_area').val($("option.country[style*='block']").first().val()) // Chrome/Firefox
        }
      }
      else {
        $('select#metro_area').val(metro).trigger('change')
      }

      $('.countries').removeClass('usa')
      $('.countries').removeClass('canada')
      $('.countries').addClass($(country).attr('data-country'))

      if (countryStr == 'usa') {
        $('label[for=city_line]').text('City/State/Zip')
        $('#nonprofits-create .lod').text('IRS Letter of Determination or Proof of Federal Exempt Status')
        $('#nonprofits-edit .lod').text('IRS Letter of Determination or Proof of Federal Exempt Status')
        $('span#exempt_letter').text('Drag PDF Here To Upload')
        $('a#download-irs-lod').text('See IRS Letter')
        $('#sample').removeClass('hidden')
        $('.state-entity').removeClass('hidden')
      }
      else if (countryStr == 'canada') {
        $('label[for=city_line]').text('City Province Postal Code')
        $('#nonprofits-create .lod').text('Canada Revenue Agency Proof of BN/Registration')
        $('#nonprofits-edit .lod').text('Canada Revenue Agency Proof of BN/Registration')
        $('span#exempt_letter').text('Drag PDF Here To Upload')
        $('a#download-irs-lod').text('See Registration Letter')
        $('#sample').addClass('hidden')
        $('.state-entity').addClass('hidden')
      }
    }
  }
  if ($('#nonprofits-create').length > 0) {
    $('.ownerSaveAsDraft a').click(function () {
      $('#saveAsDraft').trigger('click')
    })

    Dropzone.options.nonprofitLogoUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '#logo-fileinput-button',
      method: 'POST',
      url: '/nonprofits/'.concat(id, '/upload'),
      previewsContainer: '#dropzonePreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
                        // Create the remove button
          var removeButton = Dropzone.createElement("<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>")

                        // Capture the Dropzone instance as closure.
          var _this = this

                        // Listen to the click event
          removeButton.addEventListener('click', function (e) {
                            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

                            // Remove the file preview.
            _this.removeFile(file)
                            // If you want to the delete the file on the server as well,
                            // you can do the AJAX request here.
          })

                        // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('.dz-default, .dz-message').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#nonprofit-logo-url').attr('src', obj.filename)
        $('#nonprofit-logo-url').css('display', '')
      }

    }

    Dropzone.options.nonprofitPdfUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.pdf,.png,.jpg',
      clickable: '#pdf-fileinput-button',
      method: 'POST',
      url: '/nonprofits/'.concat(id, '/irs_lod/upload'),
      previewsContainer: '#dropzonePDFPreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
                        // Create the remove button
          var removeButton = Dropzone.createElement("<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>")

                        // Capture the Dropzone instance as closure.
          var _this = this

                        // Listen to the click event
          removeButton.addEventListener('click', function (e) {
                            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

                            // Remove the file preview.
            _this.removeFile(file)
                            // If you want to the delete the file on the server as well,
                            // you can do the AJAX request here.
          })

                        // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('#dropzonePDFPreview').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#nonprofit-pdf-url').attr('src', obj.filename)
        $('#irs-lod-filename').css('display', 'initial')
      }
    }

    $('#delete-irs-lod').click(function () {
      $('.nonprofits-edit').add('#deleteIRSLoDModal').modal('show')
    })

    $('#confimrDeleteIRSLod').click(function () {
      deleteTempIRSLodFile()
    })

    function deleteIRSLodFile () {
      $.get('/nonprofits/0/irs_lod/delete')
      $('#irs-lod-filename').css('display', 'none')
    };
  }

  if ($('#nonprofits-edit').length > 0) {
    $('[name=to_email]').on('change', function () {
      $('[name=to_email]').val($('[name=to_email]').val().trim())
    })
    var url = window.location.href

    $('#send-email').validate({
      rules: {
        to_email: {
          required: true,
          email: true
        },
        from_email: {
          required: true,
          email: true
        },
        subject: {
          required: true
        }
      },
      messages: {
        to_email: {
          required: 'Please provide a valid email address'
        },
        from_email: {
          required: 'Please provide a valid email address'
        },
        subject: {
          required: 'Please provide a subject'
        }
      }
    })

    $('.sendEmailButton').click(function () {
      getEmailTemplate(this.getAttribute('email-id'))
      $('.nonprofits-edit').add('#sendEmail').modal('show')
    })

    $('#send-message').click(function () {
      if ($('#send-email').valid()) {
        var error = sendMessage()
        if (error) {
          event.preventDefault()
          return false
        }
     //               $("#saveAsDraft").trigger('click');
      }
      else {
        $('.nonprofits-edit').add('#sendEmail').modal('show')
        return false
      }
    })

    $('.nonprofits-edit').add('#approve').click(function () {
      $('.nonprofits-edit').add('#approveNonprofitModal').modal('show')
    })

    $('#approve_nonprofit').click(function () {
      $('#submitApproval').trigger('click')
    })

    $('.ownerSave').click(function () {
      $('#save').trigger('click')
    })

    $('.ownerSaveAsDraft').click(function () {
      $('#saveAsDraft').trigger('click')
    })

    $('a#reviewed').click(function () {
      $('#saveAsReviewed').trigger('click')
    })

    Dropzone.options.nonprofitLogoUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '#logo-fileinput-button',
      method: 'POST',
      url: '/nonprofits/'.concat(id, '/upload'),
      previewsContainer: '#dropzonePreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
                        // Create the remove button
          var removeButton = Dropzone.createElement("<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>")

                        // Capture the Dropzone instance as closure.
          var _this = this

                        // Listen to the click event
          removeButton.addEventListener('click', function (e) {
                            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

                            // Remove the file preview.
            _this.removeFile(file)
                            // If you want to the delete the file on the server as well,
                            // you can do the AJAX request here.
          })

                        // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('.dz-default, .dz-message').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#nonprofit-logo-url').attr('src', obj.filename)
        $('#nonprofit-logo-url').css('display', '')
      }

    }

    Dropzone.options.nonprofitPdfUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.pdf',
      clickable: '#pdf-fileinput-button',
      method: 'POST',
      url: '/nonprofits/'.concat(id, '/irs_lod/upload'),
      previewsContainer: '#dropzonePDFPreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
                        // Create the remove button
          var removeButton = Dropzone.createElement("<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>")

                        // Capture the Dropzone instance as closure.
          var _this = this

                        // Listen to the click event
          removeButton.addEventListener('click', function (e) {
                            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

                            // Remove the file preview.
            _this.removeFile(file)
                            // If you want to the delete the file on the server as well,
                            // you can do the AJAX request here.
          })

                        // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('#dropzonePDFPreview').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#nonprofit-pdf-url').attr('src', obj.filename)
        $('#irs-lod-filename').css('display', 'initial')
      }
    }

    tinymce.init({
      theme: 'modern',
      selector: '#email-message',
      menubar: false,
      height: 350,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '12px'
        })
      }
    })

    $('#delete-irs-lod').click(function () {
      $('.nonprofits-edit').add('#deleteIRSLoDModal').modal('show')
    })

    $('#confimrDeleteIRSLod').click(function () {
      deleteIRSLodFile()
    })

    function deleteTempIRSLodFile () {
      $.get('/nonprofits/0/irs_lod/delete')
      $('#irs-lod-filename').css('display', 'none')
    }

    function deleteIRSLodFile () {
      $.get('/nonprofits/'.concat(url.replace(/^https?:\/\//, '').split('/')[2], '/irs_lod/delete'))
      $('#irs-lod-filename').css('display', 'none')
    }

    function getEmailTemplate (id) {
      $.getJSON('/admin/emails/merged/'.concat(id, '/', url.replace(/^https?:\/\//, '').split('/')[2]), function (data) {
        $('[name=to_email]').val(data.to_email)
        $('[name=to_name]').val(data.to_name)
        $('[name=from_email]').val(data.from_email)
        $('[name=from_name]').val(data.from_name)
        $('[name=subject]').val(data.subject)
        $('[name=message]').val(data.body)
        $('[name=next_email_template_id]').val(data.next_email_template_id)
        $('[name=template_id]').val(data.id)
        tinyMCE.get('email-message').setContent(data.body)
        $('#send-message').prop('disabled', false)
        $('#email-name-mismatch').addClass('hidden')
        if (!$('[name=message]').val().includes($('[name=name]').val())) {
          $('#send-message').prop('disabled', true)
          $('#email-name-mismatch').removeClass('hidden')
        }
      })
    }

    function sendMessage () {
      var data = JSON.stringify({
        to_email: $('#nonprofits-edit #send-message-form [name=to_email]').val(),
        to_name: $('#nonprofits-edit #send-message-form [name=to_name]').val(),
        from_email: $('#nonprofits-edit #send-message-form [name=from_email]').val(),
        from_name: $('#nonprofits-edit #send-message-form [name=from_name]').val(),
        subject: $('#nonprofits-edit #send-message-form [name=subject]').val(),
        message: tinyMCE.get('email-message').getContent({format: 'raw'}),
        messageable_id: url.replace(/^https?:\/\//, '').split('/')[2],
        messageable_type: 'Nonprofit',
        next_email_template_id: $('#nonprofits-edit #send-message-form [name=next_email_template_id]').val(),
        template_id: $('#nonprofits-edit #send-message-form [name=template_id]').val()
      })
      $.ajax({
        url: '/messages',
        dataType: 'json',
        contentType: 'application/json; charset=UTF-8',
        data: data,
        type: 'POST',
        error: function (xhr, ajaxOptions, thrownError) {
          $('#email-sent-error').show()
        }
      }).done(function (response) {
        $('#email-sent-alert').show()
      })
    }
  }
})
