$(document).ready(function () {

    if ($("#messages-datatables").length > 0) {

        var table = $('#messages-datatables').DataTable({
            "sDom": "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
            stateSave: true,
            fixedHeader: true,
            lengthMenu: [[25, 50, 100, 250, 500, -1], [25, 50, 100, 250, 500, "All"]],
            "language": {
                "zeroRecords": "You have no messages",
                "infoEmpty": "0 Results",
                "infoFiltered": "(filtered from _MAX_ total results)",
                "search": '<i class="im-search" style="float: left; padding: 5px;"> </i>',
                "lengthMenu": "<span>_MENU_</span>",
                "searchPlaceholder": 'Search Within Results',
                "info": "Showing _START_ to _END_ of _TOTAL_ results"
            },
            order: [[ 0, "desc" ]],
            columns : [
                null,
                null,
                null,
                null,
                null
            ],
            "fnDrawCallback":function(){
                if ( $(".paginate_button.active").length > 0) {
                    $('.dataTables_paginate.paging_simple_numbers')[0].style.display = "block";
                } else {
                    $('.dataTables_paginate.paging_simple_numbers')[0].style.display = "none";
                }
            }
        })
    }

})