$(document).ready(function () {
  if ($('#page-create-or-edit-form').length > 0) {
    tinymce.init({
      selector: '#page-body',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1: 'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px'
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })
  }
})
