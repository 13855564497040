$(document).ready(function () {
  if ($('#item-create-or-edit').length > 0) {
    $('input[name=on_site_code]').onoff()

    setItemUses()

    if (
      $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
        .length == 0
    ) {
      $('#children_age_ranges input:checkbox.doesntMatter').prop(
        'checked',
        true
      )
    }

    $("#children_age_ranges input:checkbox:not('.doesntMatter')").on(
      'click',
      function () {
        if ($(this).prop('checked')) {
          $('#children_age_ranges .doesntMatter').prop('checked', false)
          if (
            $(
              "#children_age_ranges input:checkbox:not(:checked):not('.doesntMatter')"
            ).length == 0
          ) {
            $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop(
              'checked',
              false
            )
            $('#children_age_ranges input:checkbox.doesntMatter').prop(
              'checked',
              true
            )
          }
        }
        else if (
          $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
            .length == 0
        ) {
          $('#children_age_ranges .doesntMatter').prop('checked', true)
        }
      }
    )

    $('#children_age_ranges input:checkbox.doesntMatter').on(
      'click',
      function () {
        if ($(this).prop('checked')) {
          $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop(
            'checked',
            false
          )
        }
      }
    )

    $('#delivery_method').on('change', function () {
      setItemUses()
    })

    $('input[name=is_consignment]').on('ifChecked', function (event) {
      $('#consignment_cost').removeClass('hidden')
    })

    $('input[name=is_consignment]').on('ifUnchecked', function (event) {
      $('#consignment_cost').addClass('hidden')
    })

    $('input[name=is_sample]').on('ifChecked', function (event) {
      setItemUses('checked')
    })

    $('input[name=is_sample]').on('ifUnchecked', function (event) {
      setItemUses('unchecked')
    })

    $('input[name=max_quantity]').keyup(function () {
      setItemUses()
    })

    $('input[name=school_filter]').on('change', function () {
      if ($('input[name=school_filter]').prop('checked')) {
        $('#school_filter').addClass('hidden')
      }
      else {
        $('#school_filter').removeClass('hidden')
      }
    })

    $('#delivery_method').on('change', function () {
      setItemUses()
    })

    $('input[name="is_online"]').on('ifChecked', function (event) {
      if ($('input[name="is_online"]:checked').val() == 1) {
        $('div.online_address').addClass('hidden')
        $('label[for="location_name"]').html('Website URL')
      }
      else {
        $('div.online_address').removeClass('hidden')
        $('label[for="location_name"]').html('Location Name')
      }
    })

    $('input[name="is_online"]').on('ifUnchecked', function (event) {
      $('div.online_address').removeClass('hidden')
      $('label[for="location_name"]').html('Location Name')
    })

    $('input[name=is_sample]').on('ifChecked', function (event) {
      setItemUses('checked')
    })

    $('input[name=is_sample]').on('ifUnchecked', function (event) {
      setItemUses('unchecked')
    })

    $('input[name=max_quantity]').keyup(function () {
      setItemUses()
    })

    if ($('#item_uses input:checkbox:checked').length == 0) {
      $('#item_uses input:checkbox').prop('checked', true)
    }

    if ($('#venue_type input:checkbox:checked').length == 0) {
      $('#venue_type input:checkbox').prop('checked', true)
    }

    if ($('#reach_methods input:checkbox:checked').length == 0) {
      $('#reach_methods input:checkbox').prop('checked', true)
    }

    if ($('#gender_ratio input:checkbox:checked').length == 0) {
      $('#gender_ratio input:checkbox').prop('checked', true)
    }

    if ($('#audience_other input:checkbox:checked').length == 0) {
      $('#audience_other input:checkbox').prop('checked', true)
    }

    if ($('#recipient_age_ranges input:checkbox:checked').length == 0) {
      $('#recipient_age_ranges input:checkbox').prop('checked', true)
    }

    if (
      $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
        .length == 0
    ) {
      $('#children_age_ranges input:checkbox.doesntMatter').prop(
        'checked',
        true
      )
    }

    if ($('#nonprofit_types input:checkbox:checked').length == 0) {
      $('#nonprofit_types input:checkbox').prop('checked', true)
    }

    if ($('#nonprofit_tax_statuses input:checkbox:checked').length == 0) {
      $('#nonprofit_tax_statuses input:checkbox').prop('checked', true)
    }

    if ($('#nonprofit_categories input:checkbox:checked').length == 0) {
      $('#nonprofit_categories input:checkbox').prop('checked', true)
    }

    if ($('#nonprofit_designations input:checkbox:checked').length == 0) {
      $('#nonprofit_designations input:checkbox').prop('checked', true)
    }

    if (
      $('select[name=expiration_type]').val() ==
      'donation_certificate_expiration_date'
    ) {
      $('#expiration_date').removeClass('hidden')
    }
    else {
      $('#expiration_date').addClass('hidden')
      $('input[name=expiration_date]').val('')
    }

    $('.noPlaceholder select').change(function (e) {
      if ($(this).children('option:selected').val() === 'placeholder') {
        e.preventDefault()
        setTimeout(function () {
          e.target.value = e.target.dataset.original || ''
        }, 1000)
        $('#missingSubscriptionModal').modal('show')
      }
    })

    $('.noSubscription').on('click', function (e) {
      e.preventDefault()
      setTimeout(function () {
        e.target.value = e.target.dataset.original || ''
      }, 1000)
      $('#missingSubscriptionModal').modal('show')
    })

    $('.wizard-actions input').on('click', function () {
      $('input[name=action]').val('save')
    })

    var id = location.pathname.split('/')[2]
    if (id == 'create') {
      var id = 0
    }

    $('#item-create-wizard').formwizard({
      formPluginEnabled: false,
      validationEnabled: false,
      focusFirstInput: true,
      textNext: 'Next >',
      textBack: '< Back',
      textSubmit: 'Submit for Approval',
      submitStepClass: 'submit-wizard',
      formOptions: {
        beforeSubmit: function (data) {
          resetEventFilterCheckboxes()
          $('input:disabled').removeAttr('disabled')
          $('select:disabled').removeAttr('disabled')
          $('textarea:disabled').removeAttr('disabled')
          $('input[name=action]').val('save')
        },
        success: function (data) {},
        resetForm: false
      },
      disableUIStyles: true,
      showSteps: true // show the step
    })

    $('.wstep').click(function () {
      $('.wizard-actions').addClass('hidden')
      var desiredStep = $(this).data('step-num') + 1
      var currentStep = $('.wstep.current').data('step-num')
      $('#item-create-wizard').formwizard(
        'show',
        $(".step[data-step-num='" + desiredStep + "']").attr('id')
      )
      $('.wizard-actions').removeClass('hidden')
    })

    $('.draft').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('draft')
      $('.item-create-or-edit-form').submit()
    })

    $('.approve').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('approve')
      $('.item-create-or-edit-form').submit()
    })

    $('.reviewed').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('reviewed')
      $('.item-create-or-edit-form').submit()
    })

    $('#item-create-or-edit').removeClass('hidden')
    $('#waiting').addClass('hidden')

    $('.save').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('save')
      $('.item-create-or-edit-form').submit()
    })

    $('.activate').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('activate')
      $('.item-create-or-edit-form').submit()
    })

    $('.archive').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('archive')
    })

    $('#archiveItem').click(function () {
      resetEventFilterCheckboxes()
      $('input:disabled').removeAttr('disabled')
      $('select:disabled').removeAttr('disabled')
      $('textarea:disabled').removeAttr('disabled')
      $('input[name=action]').val('archive')
      $('.item-create-or-edit-form').submit()
    })

    $('select[name=delivery_method]').change(function () {
      setDeliveryOption($('select[name=delivery_method]'))
    })

    $('#requestItemModal #request').click(function () {
      $('input[name=request]').click()
    })

    $('select[name=expiration_type]').change(function () {
      if (
        $('select[name=expiration_type]').val() ==
        'donation_certificate_expiration_date'
      ) {
        $('#expiration_date').removeClass('hidden')
      }
      else {
        $('#expiration_date').addClass('hidden')
        $('input[name=expiration_date]').val('')
      }
    })

    var default_expiration_date = $('input[name=expiration_date]').val()

    $('input[name=expiration_date]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minDate: '01/01/2015',
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    if (default_expiration_date == '') {
      $('input[name=expiration_date]').val('')
    }
    else {
      $('input[name=expiration_date]').val(
        displayDateFormat(default_expiration_date)
      )
    }

    $('#delete-static-pdf').click(function () {
      $('.items-edit').add('#deleteStaticPDFModal').modal('show')
    })

    $('#confimrDeleteStaticPDF').click(function () {
      deleteStaticPDF()
    })

    $('input[name=require_redemption_code]').on('ifChecked', function () {
      $('#upload_redemption_code').removeClass('hidden')
    })

    $('input[name=require_redemption_code]').on('ifUnchecked', function () {
      $('#upload_redemption_code').addClass('hidden')
    })

    $('input[name=is_sample]').on('ifClicked', function () {
      if (!$(this).data('can_have_gift_bag')) {
        $('input[name=is_sample]').iCheck('uncheck')
        $('#missingGiftBagModal').modal('show')
      }
    })

    $('.checkbox-inline')
      .has('.noSubscription')
      .click(function () {
        $('#missingSubscriptionModal').modal('show')
      })

    $('input[name=is_active_auto_send_disabled].noSubscription').iCheck(
      'disable'
    )

    $(
      'input[name=is_active_auto_send_on_request_disabled].noSubscription'
    ).iCheck('disable')

    $('input[name=is_active_auto_send_disabled].noSubscription').on(
      'ifClicked',
      function () {
        $('#missingSubscriptionModal').modal('show')
      }
    )

    $('input[name=is_active_auto_send_on_request_disabled].noSubscription').on(
      'ifClicked',
      function () {
        $('#missingSubscriptionModal').modal('show')
      }
    )

    $('input[name=is_sample]').on('ifChecked', function () {
      if (!$(this).data('can_have_gift_bag')) {
        setTimeout(function () {
          $('input[name=is_sample]').iCheck('uncheck')
        }, 1)
      }
      else {
        $('#max_quantity_label').removeClass('required')
        $('#max_quantity_sample_help').removeClass('hidden')
        $('#sample_help').removeClass('hidden')
        $('#lot_size').removeClass('hidden')
        $('#on_site_code').removeClass('hidden')
        if ($('input[name=max_quantity]').val() == '') {
          $('input[name=max_quantity]').attr('placeholder', '')
        }
      }
    })

    $('input[name=is_sample]').on('ifUnchecked', function () {
      $('#max_quantity_label').addClass('required')
      $('#max_quantity_sample_help').removeClass('hidden')
      $('#sample_help').addClass('hidden')
      $('#lot_size').addClass('hidden')
      $('#on_site_code').addClass('hidden')
    })

    setDeliveryOption($('select[name=delivery_method]'))

    $('input[name=is_not_hidden]').not('[type=hidden]').onoff()
    $('input[name=is_not_referred_only]').not('[type=hidden]').onoff()

    //     if($("input#location_type_metro_area:radio").attr("checked", true)

    $('input#location_type_metro_area:radio').on('ifChecked', function () {
      $('#zip_codes_group').addClass('hidden')
      $('#distance_group').addClass('hidden')
      $('#metro_areas_group').removeClass('hidden')
    })

    $('input#location_type_metro_area:radio').on('ifUnchecked', function () {
      $('#zip_codes_group').removeClass('hidden')
      $('#distance_group').removeClass('hidden')
      $('#metro_areas_group').addClass('hidden')
    })

    $('input#location_type_zip_code:radio').on('ifChecked', function () {
      $('#zip_codes_group').removeClass('hidden')
      $('#distance_group').removeClass('hidden')
      $('#metro_areas_group').addClass('hidden')
    })

    $('input#location_type_zip_code:radio').on('ifUnchecked', function () {
      $('#zip_codes_group').addClass('hidden')
      $('#distance_group').addClass('hidden')
      $('#metro_areas_group').removeClass('hidden')
    })

    var placeholder = getItemMetroAreaPlaceHolder()

    $('.metro-area-multiple').select2({
      placeholder: placeholder,
      allowClear: true,
      width: 'resolve',
      multiple: true,
      closeOnSelect: false,
      dropdownAutoWidth: true
    })

    $('.tagsInput').tagsInput({
      defaultText: 'add a zip code',
      interactive: true
    })

    $('select[name=include_tags\\[\\]]').select2({
      placeholder: 'Select Criteria',
      allowClear: true,
      width: 'resolve',
      multiple: true,
      closeOnSelect: false
    })

    $('select[name=exclude_tags\\[\\]]').select2({
      placeholder: 'Select Criteria',
      allowClear: true,
      width: 'resolve',
      multiple: true,
      closeOnSelect: false
    })

    var eventBeginDate = $('input[name=begin_date]').val()

    $('input[name=begin_date]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      changeYear: true,
      minDate: '01/01/2015',
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    if (eventBeginDate == '') {
      $('input[name=begin_date]').val('')
    }
    else {
      $('input[name=begin_date]').val(displayDateFormat(eventBeginDate))
    }

    var eventEndDate = $('input[name=end_date]').val()

    $('input[name=end_date]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minDate: '01/01/2015',
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    if (eventEndDate == '') {
      $('input[name=end_date]').val('')
    }
    else {
      $('input[name=end_date]').val(displayDateFormat(eventEndDate))
    }

    $('#clear_end_date').click(function () {
      $('input[name=end_date]').val('')
    })

    $('#clear_begin_date').click(function () {
      $('input[name=begin_date]').val('')
    })

    $('#delete-item-banner').click(function () {
      $('.items-edit').add('#deleteItemBannerModal').modal('show')
    })

    $('#confirmItemBanner').click(function () {
      deleteItemBannerImage()
    })

    Dropzone.options.itemBannerUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '.banner-fileinput-button',
      method: 'POST',
      url: '/items/'.concat(id, '/upload/banner'),
      previewsContainer: '#dropzoneBannerPreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#fileUploadErrorModal').modal('show')
        })
        this.on('addedfile', function (file) {
          // Create the remove button
          var removeButton = Dropzone.createElement(
            "<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>"
          )

          // Capture the Dropzone instance as closure.
          var _this = this

          // Listen to the click event
          removeButton.addEventListener('click', function (e) {
            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

            // Remove the file preview.
            _this.removeFile(file)
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          })

          // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        let obj = JSON.parse(response)
        $('#dropzoneBannerPreview').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#item-banner-url').attr('src', obj.filename)
        $('#item-banner-url').removeClass('hidden')
        $('#delete-item-banner').removeClass('hidden')
        $('#item-filename').css('display', 'initial')
        $("[name='banner_id']").val(obj.id)
      }
    }

    Dropzone.options.itemLogoUpload = {
      maxFilesize: 2, // MB
      maxFiles: 1,
      acceptedFiles: '.png,.jpg,.img',
      clickable: '.logo-fileinput-button',
      method: 'POST',
      url: '/items/'.concat(id, '/upload/logo'),
      previewsContainer: '#dropzoneLogoPreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#errorModal').modal('show')
        })
        this.on('addedfile', function (file) {
          // Create the remove button
          var removeButton = Dropzone.createElement(
            "<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>"
          )

          // Capture the Dropzone instance as closure.
          var _this = this

          // Listen to the click event
          removeButton.addEventListener('click', function (e) {
            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

            // Remove the file preview.
            _this.removeFile(file)
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          })

          // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('#dropzoneLogoPreview').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#item-logo-url').attr('src', obj.filename)
        $('#item-logo-url').removeClass('hidden')
        $('#item-filename').css('display', 'initial')
      }
    }

    Dropzone.options.itemPdfUpload = {
      maxFilesize: 5, // MB
      maxFiles: 1,
      acceptedFiles: '.pdf',
      clickable: '.pdf-fileinput-button',
      method: 'POST',
      url: '/items/'.concat(id, '/upload/pdf'),
      previewsContainer: '#dropzonePDFPreview',
      init: function () {
        this.on('error', function (file, errorMessage, xhr) {
          var response = JSON.parse(xhr.response).file[0]
          $('.dz-error-message').text(response)
          $('.modal-body').text(response)
          $('#myModal').modal('show')
        })
        this.on('addedfile', function (file) {
          // Create the remove button
          var removeButton = Dropzone.createElement(
            "<button class=\"btn btn-success\" style='position: absolute; top: -95px; left: 0px; margin: 20px 0px 0px 10px;'>Remove file</button>"
          )

          // Capture the Dropzone instance as closure.
          var _this = this

          // Listen to the click event
          removeButton.addEventListener('click', function (e) {
            // Make sure the button click doesn't submit the form:
            e.preventDefault()
            e.stopPropagation()

            // Remove the file preview.
            _this.removeFile(file)
            // If you want to the delete the file on the server as well,
            // you can do the AJAX request here.
          })

          // Add the button to the file preview element.
          file.previewElement.appendChild(removeButton)
        })
      },
      success: function (file, response) {
        obj = JSON.parse(response)
        $('#dropzonePDFPreview').children('img').attr('src', obj.filename)
        this.removeFile(file)
        $('#static-pdf-filename').removeClass('hidden')
        $('#item-filename').css('display', 'initial')
      }
    }

    tinymce.init({
      selector: '.html-textarea',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1:
        'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px'
          this.getDoc().body.style.color = $('textarea').css('color')
          delete ed.buttons.link
        })
      }
    })

    tinymce.init({
      selector: '.html-textarea-no-link',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1:
        'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px'
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })
    $('input#country').on('ifChecked', function () {
      set_item_country(this)
    })

    if ($('input#country[type=radio]').not(':checked').length == 2) {
      $('input#country').first().iCheck('check')
    }
    else {
      set_item_country($('input#country[type=radio]:checked'))
    }

    function set_item_country (country) {
      // Odd - on reload (with errors) the value isn't being set, but the attribute is
      $('input[name=pickup_zip_code]').val(
        $('input[name=pickup_zip_code]').attr('value')
      )

      var countryStr = $(country).attr('data-country')

      $('input[name=pickup_zip_code]').removeClass('hidden')
      $('div.country').not('.item').addClass('hidden')
      $('div.' + $(country).attr('data-country')).removeClass('hidden')
      $('option.' + $(country).attr('data-country')).removeClass('hidden')
      $('input.mask-zipCode').removeClass('usa')
      $('input.mask-zipCode').removeClass('canada')
      $('input.mask-zipCode').addClass($(country).attr('data-country'))
      $('.mask-zipCode.usa').mask('99999')
      $('.mask-zipCode.canada').mask('a9a9a9')
      $('.mask-zipCode.usa').attr('placeholder', '99999')
      $('.mask-zipCode.canada').attr('placeholder', 'A9A9A9')

      if (countryStr == 'usa') {
        $('label[for=city_line]').text('City/State/Zip')
      }
      else if (countryStr == 'canada') {
        $('label[for=city_line]').text('City Province Postal Code')
      }
    }
  }
  if ($('#item-datatables').length > 0) {
    $('#item-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      fixedHeader: true,
      lengthMenu: [
        [10, 20, -1],
        [10, 20, 'All']
      ],
      language: {
        zeroRecords: 'Please create an item',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search" style="float: left; padding: 5px;"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search Within Results'
      },
      columns: [null, null, null, null, null, { orderable: false }]
    })
  }
})

function setDeliveryOption (option) {
  switch (option.val()) {
    case 'auto':
      $('.delivery_method #auto').removeClass('hidden')
      $('.delivery_method #delivery').addClass('hidden')
      $('.delivery_method #pickup').addClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('.values').removeClass('hidden')
      $('#lead_time').addClass('hidden')
      $('#help_special_instructions').removeClass('hidden')
      break

    case 'ship':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').removeClass('hidden')
      $('.delivery_method #pickup').addClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('.values').removeClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break

    case 'ship_preferred':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').addClass('hidden')
      $('.delivery_method #pickup').removeClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('.values').removeClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break

    case 'pickup':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').addClass('hidden')
      $('.delivery_method #pickup').removeClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').removeClass('hidden')
      $('.values').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break

    case 'pickup_preferred':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').addClass('hidden')
      $('.delivery_method #pickup').removeClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').removeClass('hidden')
      $('.values').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break

    case 'static_pdf':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').addClass('hidden')
      $('.delivery_method #pickup').addClass('hidden')
      $('.delivery_method #static_pdf').removeClass('hidden')
      $('#contact').addClass('hidden')
      $('.values').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break

    case 'service':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').addClass('hidden')
      $('.delivery_method #pickup').addClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').removeClass('hidden')
      $('#placeholder').addClass('hidden')
      $('.values').removeClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break

    case 'placeholder':
      $('.delivery_method #auto').addClass('hidden')
      $('.delivery_method #ship').addClass('hidden')
      $('.delivery_method #pickup').addClass('hidden')
      $('.delivery_method #static_pdf').addClass('hidden')
      $('#contact').addClass('hidden')
      $('#placeholder').removeClass('hidden')
      $('.values').addClass('hidden')
      $('#lead_time').removeClass('hidden')
      $('#help_special_instructions').addClass('hidden')
      break
  }
}

function setItemUses (action) {
  if (
    $('#delivery_method').val() == 'service' ||
    action == 'checked' ||
    ($('input[name=is_sample]').prop('checked') &&
      typeof action === 'undefined')
  ) {
    $('#item_uses').addClass('hidden')
    $('.sample').addClass('hidden')
    if (
      $('.item-create-form').length > 0 &&
      $('#delivery_method').val() == 'auto'
    ) {
      $('#delivery_method').val('ship')
    }
  }
  else {
    $('#item_uses').removeClass('hidden')
    $('.sample').removeClass('hidden')
  }
}

function deleteItemBannerImage () {
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('#token').attr('content')
    }
  })

  $.ajax({
    url: '/images/'.concat(
      $("[name='banner_id']").val() ? $("[name='banner_id']").val() : 'banner'
    ),
    type: 'DELETE',
    success: function (result) {
      $('#item-banner-url').addClass('hidden')
      $('#delete-item-banner').addClass('hidden')
    }
  })
}

function deleteStaticPDF () {
  $.ajaxSetup({
    headers: {
      'X-CSRF-TOKEN': $('#token').attr('content')
    }
  })

  $.ajax({
    url: '/items/'.concat($("[name='id']").val(), '/static_pdf/delete'),
    type: 'POST',
    success: function (result) {
      $('#static-pdf').css('display', 'none')
      $('#static-pdf-filename').css('display', 'none')
    }
  })
}
