/**
 * Created by donationmatch on 4/17/15 (c).
 */
$(document).ready(function() {
    $('#organization-datatables').dataTable({
        "sDom": "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
        stateSave: true,
        fixedHeader: true,
        lengthMenu: [[25, 50, 100, -1], [25, 50, 100, "All"]],
        "language": {
            "zeroRecords": "Sorry, there were no results for the selected criteria",
            "infoEmpty": "0 Results",
            "infoFiltered": "(filtered from _MAX_ total results)",
            "search": '<i class="im-search"> </i>',
            "lengthMenu": "<span>_MENU_</span>",
            "searchPlaceholder": 'Search...',
            "info": "Showing _START_ to _END_ of _TOTAL_ results"
        },
        "fnDrawCallback":function() {
            if ( $(".paginate_button.active").length > 0) {
                $('.dataTables_paginate.paging_simple_numbers')[0].style.display = "block";
            } else {
                $('.dataTables_paginate.paging_simple_numbers')[0].style.display = "none";
            }
        }
    });

    $("#organization-filter-type").click(function () {
        $("#organization-filter-type").attr("disabled", true);
        $('#filter').submit();
    });

    $('input[name^="type"]').add('input[name^="status"]').add('input[name^="tags"]').on('ifToggled', function () {
        $('#filter').submit();
    });
});