$(document).ready(function () {
  if ($('#user-edit-form').length > 0) {
    $('input[name=all_messages]').on('ifChecked', function (event) {
      $('input[name=allow_all_messages]').iCheck('check')
      $('input[name=allow_critical_messages]').iCheck('check')
      $('input[name=allow_summary_messages]').iCheck('check')
    })

    $('.messages').on('ifUnchecked', function (event) {
      $('input[name=all_messages]').iCheck('uncheck')
    })

    $('.messages').on('ifChecked', function (event) {
      if ($('.messages:checkbox:checked').length == 3) {
        $('input[name=all_messages]').iCheck('check')
      }
    })

    $('#user-datatables').dataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'All']],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      processing: true,
      serverSide: true,
      ajax: '/users/data',
      columns: [
        { data: 'first_name', name: 'first_name' },
        { data: 'last_name', name: 'last_name' },
        { data: 'email', name: 'email' },
        { data: 'name', name: 'organizations.name' },
        { data: 'link', name: 'organizations.id' }
      ],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block'
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none'
        }
      }
    })

    $('#authorized-user-datatables').dataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'All']],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      processing: true,
      serverSide: true,
      ajax: '/users/authdata',
      columns: [
        { data: 'email', name: 'email' },
        { data: 'first_name', name: 'first_name' },
        { data: 'last_name', name: 'last_name' },
        { data: 'access', name: 'access' },
        { data: 'name', name: 'organizations.name' },
        { data: 'link', name: 'organizations.id' }
      ],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block'
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none'
        }
      }
    })

    $('#user-authorized-user-datatables').dataTable({
      sDom:
        "<'row'<'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [[25, 50, 100, -1], [25, 50, 100, 'All']],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      processing: true,
      serverSide: true,
      ajax: '/users/authdata',
      columns: [
        { data: 'email', name: 'email' },
        { data: 'first_name', name: 'first_name' },
        { data: 'last_name', name: 'last_name' },
        { data: 'access', name: 'access' },
        { data: 'link', name: 'organizations.id' }
      ],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block'
        }
        else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none'
        }
      }
    })

    $('.row-count-pre').html('Show')
    $('.row-count-post').html('per page')

    $('#user-edit-password-form').validate({
      ignore: null,
      ignore: 'input[type="hidden"]',
      errorPlacement: function (error, element) {
        wrap = element.parent()
        wrap1 = wrap.parent()
        if (wrap1.hasClass('checkbox')) {
          error.insertAfter(wrap1)
        }
        else {
          if (element.attr('type') == 'file') {
            error.insertAfter(element.next())
          }
          else {
            error.insertAfter(element)
          }
        }
      },
      errorClass: 'help-block',
      rules: {
        first_name: 'required',
        last_name: 'required',
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 6
        },
        password_confirmation: {
          required: true,
          minlength: 6,
          equalTo: '#password'
        },
        terms: 'required'
      },
      messages: {
        password: {
          required: 'Please provide a password',
          minlength: 'Your password must be at least 5 characters long'
        },
        password_confirmation: {
          required: 'Please provide a password',
          minlength: 'Your password must be at least 5 characters long',
          equalTo: 'Please enter the same password as above'
        }
      },
      highlight: function (element) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
        else {
          if ($(element).attr('type') == 'file') {
            $(element)
              .parent()
              .parent()
              .removeClass('has-success')
              .addClass('has-error')
          }
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
      },
      unhighlight: function (element, errorClass) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('checkbox')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .next()
            .hasClass('bootstrap-filestyle')
        ) {
          $(element)
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
        else {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
      }
    })

    $('#add_authorized_user').validate({
      ignore: null,
      ignore: 'input[type="hidden"]',
      errorPlacement: function (error, element) {
        wrap = element.parent()
        wrap1 = wrap.parent()
        if (wrap1.hasClass('checkbox')) {
          error.insertAfter(wrap1)
        }
        else {
          if (element.attr('type') == 'file') {
            error.insertAfter(element.next())
          }
          else {
            error.insertAfter(element)
          }
        }
      },
      errorClass: 'help-block',
      rules: {
        email: {
          required: true,
          email: true
        },
        first_name: {
          required: true
        },
        last_name: {
          required: true
        }
      },
      highlight: function (element) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
        else {
          if ($(element).attr('type') == 'file') {
            $(element)
              .parent()
              .parent()
              .removeClass('has-success')
              .addClass('has-error')
          }
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
      },
      unhighlight: function (element, errorClass) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('checkbox')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .next()
            .hasClass('bootstrap-filestyle')
        ) {
          $(element)
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
        else {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
      }
    })
  }
})
