/**
 * Created by darrylrettig on 3/28/15.
 */

$(document).ready(function () {
  if ($('.login-page').length) {
    var hash = document.location.hash
    var prefix = 'tab_'
    if (hash) {
      $('.nav-tabs a[href=' + hash.replace(prefix, '') + ']').tab('show')
    }

    // Change hash for page-reload
    $('.nav-tabs a').on('shown', function (e) {
      window.location.hash = e.target.hash.replace('#', '#' + prefix)
    })

    // switch to register tab if that's where the user last entered

    var register = $('#login_register_first_name').get(0)

    if (register != null) {
      if (register.value != '') {
        $('.nav-tabs a[href=#register]').tab('show')
      }
    }

    $('#login-validate').validate({
      ignore: null,
      ignore: 'input[type="hidden"]',
      errorPlacement: function (error, element) {
        wrap = element.parent()
        wrap1 = wrap.parent()
        if (wrap1.hasClass('checkbox')) {
          error.insertAfter(wrap1)
        }
        else {
          if (element.attr('type') == 'file') {
            error.insertAfter(element.next())
          }
          else {
            error.insertAfter(element)
          }
        }
      },
      errorClass: 'help-block',
      rules: {
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 5
        }
      },
      messages: {
        password: {
          required: 'Please provide a password',
          minlength: 'Your password must be at least 5 characters long'
        }
      },
      highlight: function (element) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
        else {
          if ($(element).attr('type') == 'file') {
            $(element)
              .parent()
              .parent()
              .removeClass('has-success')
              .addClass('has-error')
          }
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
      },
      unhighlight: function (element, errorClass) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('checkbox')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .next()
            .hasClass('bootstrap-filestyle')
        ) {
          $(element)
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
        else {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
      }
    })
    $('#login-register-validate').validate({
      ignore: null,
      ignore: 'input[type="hidden"]',
      errorPlacement: function (error, element) {
        wrap = element.parent()
        wrap1 = wrap.parent()
        if (wrap1.hasClass('checkbox')) {
          error.insertAfter(wrap1)
        }
        else {
          if (element.attr('type') == 'file') {
            error.insertAfter(element.next())
          }
          else {
            error.insertAfter(element)
          }
        }
      },
      errorClass: 'help-block',
      rules: {
        first_name: 'required',
        last_name: 'required',
        email: {
          required: true,
          email: true
        },
        password: {
          required: true,
          minlength: 5
        },
        password_confirmation: {
          required: true,
          minlength: 5,
          equalTo: '#password'
        },
        terms: 'required',
        opt_in: 'required'
      },
      messages: {
        password: {
          required: 'Please provide a password',
          minlength: 'Your password must be at least 5 characters long'
        },
        password_confirmation: {
          required: 'Please provide a password',
          minlength: 'Your password must be at least 5 characters long',
          equalTo: 'Please enter the same password as above'
        }
      },
      highlight: function (element) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
        else {
          if ($(element).attr('type') == 'file') {
            $(element)
              .parent()
              .parent()
              .removeClass('has-success')
              .addClass('has-error')
          }
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
      },
      unhighlight: function (element, errorClass) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('checkbox')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .next()
            .hasClass('bootstrap-filestyle')
        ) {
          $(element)
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
        else {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
      }
    })
    $('#pasword-reset').validate({
      ignore: null,
      ignore: 'input[type="hidden"]',
      errorPlacement: function (error, element) {
        wrap = element.parent()
        wrap1 = wrap.parent()
        if (wrap1.hasClass('checkbox')) {
          error.insertAfter(wrap1)
        }
        else {
          if (element.attr('type') == 'file') {
            error.insertAfter(element.next())
          }
          else {
            error.insertAfter(element)
          }
        }
      },
      errorClass: 'help-block',
      rules: {
        email: {
          required: true,
          email: true
        }
      },
      highlight: function (element) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
        else {
          if ($(element).attr('type') == 'file') {
            $(element)
              .parent()
              .parent()
              .removeClass('has-success')
              .addClass('has-error')
          }
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-success')
            .addClass('has-error')
        }
      },
      unhighlight: function (element, errorClass) {
        if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('form-group')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .offsetParent()
            .parent()
            .hasClass('checkbox')
        ) {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
          $(element.form)
            .find('label[for=' + element.id + ']')
            .removeClass(errorClass)
        }
        else if (
          $(element)
            .next()
            .hasClass('bootstrap-filestyle')
        ) {
          $(element)
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
        else {
          $(element)
            .offsetParent()
            .parent()
            .parent()
            .removeClass('has-error')
            .addClass('has-success')
        }
      }
    })
  }
})
