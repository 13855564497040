/* eslint-disable */
$(document).ready(function () {
  if ($("#filters-event-create").length > 0) {
    $("input[name=event_begin_date], input[name=event_end_date]").on(
      "click",
      function (event) {
        $("input#date_type_days").iCheck("uncheck");
        $("input#date_type_range").iCheck("check");
      }
    );

    $("input[name=on_site_access]").onoff();

    $("input[name=delta]").on("click", function (event) {
      $("input#date_type_days").iCheck("check");
      $("input#date_type_range").iCheck("uncheck");
    });

    if (!$("input.country:checked").length) {
      $("input[name^=countries][data-country=usa]").iCheck("check");
    }

    $(".noSubscription").on("click", function (e) {
      $("#missingSubscriptionModal").modal("show");
      e.preventDefault();
    });

    if ($("#noResults").length > 0) {
      $("#eventFilter").modal("show");
      $.fn.modal.Constructor.prototype.enforceFocus = function () {};
    }

    if (
      $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
        .length == 0
    ) {
      $("#children_age_ranges input:checkbox.doesntMatter").prop(
        "checked",
        true
      );
    }

    $("input[name=school_filter]").on("change", function () {
      if ($("input[name=school_filter]").prop("checked")) {
        $("#school_filter").addClass("hidden");
      } else {
        $("#school_filter").removeClass("hidden");
      }
    });

    $("#children_age_ranges input:checkbox:not('.doesntMatter')").on(
      "click",
      function () {
        if ($(this).prop("checked")) {
          $("#children_age_ranges .doesntMatter").prop("checked", false);
          if (
            $(
              "#children_age_ranges input:checkbox:not(:checked):not('.doesntMatter')"
            ).length == 0
          ) {
            $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop(
              "checked",
              false
            );
            $("#children_age_ranges input:checkbox.doesntMatter").prop(
              "checked",
              true
            );
          }
        } else if (
          $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
            .length == 0
        ) {
          $("#children_age_ranges .doesntMatter").prop("checked", true);
        }
      }
    );

    $("#children_age_ranges input:checkbox.doesntMatter").on(
      "click",
      function () {
        if ($(this).prop("checked")) {
          $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop(
            "checked",
            false
          );
        }
      }
    );

    $("#update").on("click", function () {
      if ($("#item_uses input:checkbox:not(:checked)").length == 0) {
        $("#item_uses input:checkbox").prop("checked", false);
      }

      if ($("#reach_methods input:checkbox:not(:checked)").length == 0) {
        $("#reach_methods input:checkbox").prop("checked", false);
      }

      if ($("#gender_ratio input:checkbox:not(:checked)").length == 0) {
        $("#gender_ratio input:checkbox").prop("checked", false);
      }

      if ($("#audience_other input:checkbox:not(:checked)").length == 0) {
        $("#audience_other input:checkbox").prop("checked", false);
      }

      if ($("#recipient_age_ranges input:checkbox:not(:checked)").length == 0) {
        $("#recipient_age_ranges input:checkbox").prop("checked", false);
      }

      if (
        $(
          "#children_age_ranges input:checkbox:not(:checked):not('.doesntMatter')"
        ).length == 0
      ) {
        $("#children_age_ranges input:checkbox").prop("checked", false);
      }

      if ($("#nonprofit_types input:checkbox:not(:checked)").length == 0) {
        $("#nonprofit_types input:checkbox").prop("checked", false);
      }

      if (
        $("#nonprofit_tax_statuses input:checkbox:not(:checked)").length == 0
      ) {
        $("#nonprofit_tax_statuses input:checkbox").prop("checked", false);
      }

      if ($("#nonprofit_categories input:checkbox:not(:checked)").length == 0) {
        $("#nonprofit_categories input:checkbox").prop("checked", false);
      }

      if (
        $("#nonprofit_designations input:checkbox:not(:checked)").length == 0
      ) {
        $("#nonprofit_designations input:checkbox").prop("checked", false);
      }
    });

    if ($("#create-search-wizard").length == 0) {
      //Don't run when wizard
      if ($("#item_uses input:checkbox:checked").length == 0) {
        $("#item_uses input:checkbox").prop("checked", true);
      }

      if ($("#venue_type input:checkbox:checked").length == 0) {
        $("#venue_type input:checkbox").prop("checked", true);
      }

      if ($("#reach_methods input:checkbox:checked").length == 0) {
        $("#reach_methods input:checkbox").prop("checked", true);
      }

      if ($("#gender_ratio input:checkbox:checked").length == 0) {
        $("#gender_ratio input:checkbox").prop("checked", true);
      }

      if ($("#audience_other input:checkbox:checked").length == 0) {
        $("#audience_other input:checkbox").prop("checked", true);
      }

      if ($("#recipient_age_ranges input:checkbox:checked").length == 0) {
        $("#recipient_age_ranges input:checkbox").prop("checked", true);
      }

      if (
        $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
          .length == 0
      ) {
        $("#children_age_ranges input:checkbox.doesntMatter").prop(
          "checked",
          true
        );
      }

      if ($("#nonprofit_types input:checkbox:checked").length == 0) {
        $("#nonprofit_types input:checkbox").prop("checked", true);
      }

      if ($("#nonprofit_tax_statuses input:checkbox:checked").length == 0) {
        $("#nonprofit_tax_statuses input:checkbox").prop("checked", true);
      }

      if ($("#nonprofit_categories input:checkbox:checked").length == 0) {
        $("#nonprofit_categories input:checkbox").prop("checked", true);
      }

      if ($("#nonprofit_designations input:checkbox:checked").length == 0) {
        $("#nonprofit_designations input:checkbox").prop("checked", true);
      }
    }

    $("input#search_type_item:radio").on("ifChecked", function () {
      $("#search_type").addClass("hidden");
    });

    $("input#search_type_custom:radio").on("ifChecked", function () {
      $("#search_type").removeClass("hidden");
    });

    $("select#items").on("change", function () {
      if ($("select#items").val()) {
        $("#search_type").addClass("hidden");
        $("input#search_type_item:radio").iCheck("check");
      }
    });

    $("input#location_type_metro_area:radio").on("ifChecked", function () {
      $("#zip_codes_group").addClass("hidden");
      $("#distance_group").addClass("hidden");
      $("div#metro_areas_group").removeClass("hidden");
      $("#metro_area_div").removeClass("hidden");
    });

    $("input#location_type_metro_area:radio").on("ifUnchecked", function () {
      $("#zip_codes_group").removeClass("hidden");
      $("#distance_group").removeClass("hidden");
      $("div#metro_areas_group").addClass("hidden");
      $("#metro_area_div").addClass("hidden");
    });

    $("input#location_type_zip_code:radio").on("ifChecked", function () {
      $("#zip_codes_group").removeClass("hidden");
      $("#distance_group").removeClass("hidden");
      $("#metro_area_div").addClass("hidden");
    });

    $("input#location_type_zip_code:radio").on("ifUnchecked", function () {
      $("#zip_codes_group").addClass("hidden");
      $("#distance_group").addClass("hidden");
      $("#metro_area_div").removeClass("hidden");
    });

    $("#show-results").click(function () {
      $("input[disabled]").removeAttr("disabled");
      $("#create-search-wizard").submit();
    });

    $("#backWizardButton").click(function (button) {
      $("input.btn.ui-wizard-content.ui-formwizard-button").removeClass(
        "btn-success"
      );
      $("input.btn.ui-wizard-content.ui-formwizard-button").addClass(
        "btn-primary"
      );
      $("#show-results").removeClass("hidden");
      $("#submit-results-span").removeClass("hidden");
    });

    $("#nextWizardButton").click(function (button) {
      if (
        $(".submit_step").css("display") == "none" &&
        $("#where").css("display") == "none"
      ) {
        $("input.btn.ui-wizard-content.ui-formwizard-button").addClass(
          "btn-success"
        );
        $("input.btn.ui-wizard-content.ui-formwizard-button").removeClass(
          "btn-primary"
        );
        $("#show-results").addClass("hidden");
        $("#submit-results-span").addClass("hidden");
      }
    });

    $("#date-range").on("change", function () {
      var from_date = new Date();
      var to_date = new Date();
      to_date.setMonth(to_date.getMonth() + Number(this.value));

      $("input[name=wizard_event_begin_date]").val(
        from_date.getMonth() +
          1 +
          "/" +
          from_date.getDate() +
          "/" +
          from_date.getFullYear()
      );
      $("input[name=wizard_event_end_date]").val(
        to_date.getMonth() +
          1 +
          "/" +
          to_date.getDate() +
          "/" +
          to_date.getFullYear()
      );
    });

    var wizardEventBeginDate = $("input[name=wizard_event_begin_date]").val();

    $("input[name=wizard_event_begin_date]").daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MM/DD/YYYY",
      },
    });

    if (wizardEventBeginDate == "") {
      $("input[name=wizard_event_begin_date]").val("");
    }

    var wizardEventEndDate = $("input[name=wizard_event_end_date]").val();

    $("input[name=wizard_event_end_date]").daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MM/DD/YYYY",
      },
    });

    if (wizardEventEndDate == "") {
      $("input[name=wizard_event_end_date]").val("");
    }

    $("#wizard_event_clear_end_date").click(function () {
      $("input[name=wizard_event_end_date]").val("");
    });

    $("#wizard_event_clear_end_date").click(function () {
      $("input[name=wizard_event_end_date]").val("");
    });

    $("#eventFilter").on("show.bs.modal", function () {
      $.fn.modal.Constructor.prototype.enforceFocus = function () {};
    });

    var eventBeginDate = $("input[name=event_begin_date]").val();

    $("input[name=event_begin_date]").daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MM/DD/YYYY",
      },
    });

    if (eventBeginDate == "") {
      $("input[name=event_begin_date]").val("");
    }

    var eventEndDate = $("input[name=event_end_date]").val();

    $("input[name=event_end_date]").daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MM/DD/YYYY",
      },
    });

    if (eventEndDate == "") {
      $("input[name=event_end_date]").val("");
    }

    $("#event_clear_begin_date").click(function () {
      $("input[name=event_begin_date]").val("");
    });

    $("#event_clear_end_date").click(function () {
      $("input[name=event_end_date]").val("");
    });

    var placeholder = getItemMetroAreaPlaceHolder();

    $(".metro-area-multiple").select2({
      placeholder: placeholder,
      allowClear: true,
      width: "100%",
      multiple: true,
      closeOnSelect: false,
      dropdownAutoWidth: true,
    });

    $(".metro-area-multiple").on("change", function (e) {
      var selected = $(this).val();

      if (selected[0] == "0") {
        $('.metro-area-multiple option[value="0"]').removeAttr("selected");
      }
    });

    $("select[name=metro_areas\\[\\]]").on("change", function (e) {
      var selected = $(this).val();

      if (selected[0] == "0") {
        $("select[name=metro_areas\\[\\]]").removeAttr("selected");
      }
    });

    $("select[name=include_tags\\[\\]]").select2({
      placeholder: "Select Criteria",
      allowClear: true,
      width: "resolve",
      multiple: true,
      closeOnSelect: false,
    });

    $("select[name=exclude_tags\\[\\]]").select2({
      placeholder: "Select Criteria",
      allowClear: true,
      width: "resolve",
      multiple: true,
      closeOnSelect: false,
    });

    $("input[name*=event]").on("click", function () {
      update_stats();
    });

    var columns = $("#bulk-offer").length ? [{ orderable: false }, null, null, null, null, null, null, null] : [null, null, null, null, null, null, null];

    var table = $("#event-datatables").DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, "All"],
      ],
      language: {
        zeroRecords: "Sorry, there were no results for the selected criteria",
        infoEmpty: "0 Results",
        infoFiltered: "(filtered from _MAX_ total results)",
        search: '<i class="im-search" style="float: left; padding: 5px;"> </i>',
        lengthMenu: "<span>_MENU_</span>",
        searchPlaceholder: "Search Within Results",
        info: "Showing _START_ to _END_ of _TOTAL_ results",
      },
      columns: columns,
      order: [[2, "asc"]],
      fnDrawCallback: function () {
        $("#filter_button").removeClass("hidden");
        $("#filter_spinner").addClass("hidden");
        if ($(".paginate_button.active").length > 0) {
          $(".dataTables_paginate.paging_simple_numbers")[0].style.display =
            "block";
        } else {
          $(".dataTables_paginate.paging_simple_numbers")[0].style.display =
            "none";
        }
        update_stats();
      },
    });

    $("div.dataTables_filter").appendTo($(".dt_filter"));
    $(".dt_filter input").attr("style", "width: 80% !important");

    $("input.subscription[name=select_all]").on("click", function () {
      $("input[name*='event']").prop("checked", $(this).is(":checked"));
      if ($("input[name=select_all]").prop("checked")) {
        $("button[name=action]").html(
          '<i class="fa-level-down"> </i> Clear All'
        );
      } else {
        $("button[name=action]").html(
          '<i class="fa-level-down"> </i> Select All'
        );
      }

      update_stats();
    });

    $("button.subscription[name=action]").on("click", function () {
      $("input[name=select_all]").click();
    });

    $("#action").insertBefore(
      $(".table-search:eq(0)", table.table().container())
    );

    $("select[name=event-datatables_length]").addClass("input-md");
    $("select[name=event-datatables_length]").removeClass("input-sm");

    $("[name=sort]").change(function () {
      var table = $("#event-datatables").DataTable();
      table.order([Number($(this).val()) + 2, "asc"]).draw();
    });

    $(".row-count-pre").html("Show");
    $(".row-count-post").html("per page");

    $(".tags[name=zip_codes]").tagsInput({
      width: "auto",
      defaultText: "add a zip code",
    });

    $(document).on("click", "#offer_item", function () {
      $("input[name=event_id]").val($(this).data("event-id"));
      $("#offer-title").text("Offer " + $(this).data("event-name"));
      $("#offer-body").text(
        "Are you sure you want to offer your item to " +
          $(this).data("nonprofit-name") +
          "?"
      );
    });

    $("#offerItemModal #offer").click(function () {
      $("input[name=offer]").click();
    });

    //       $("#stats").insertBefore($('#event-datatables'));
    $("#stats").removeClass("hidden");

    $(".offered-item").on("click", function () {
      $("#bulkOfferItemModal #offer").addClass("hidden");
      $("#offer-title").html("Offer " + $(this).data("name"));

      if ($("#stats").data("checked") == 0) {
        $("#offer-body").html(
          "You have not selected any events to offer your item."
        );
      } else {
        $("#bulkOfferItemModal #offer").removeClass("hidden");
        $("#bulkOfferItemModal input[name=item_id]").val($(this).data("id"));
        $("#offer-body").html(
          "Are you sure you want to offer your " +
            $(this).data("name") +
            " to " +
            $("#stats").data("checked") +
            " event(s)?"
        );
      }
    });

    $("#bulkOfferItemModal #offer").click(function () {
      $("input[name=offer]").click();
    });
  }

  function update_stats() {
    $("[name=select_all]").prop(
      "checked",
      $("input[name*=event]:checkbox").length ===
        $("input[name*=event]:checkbox:checked").length
    );
    $("#stats").data("checked", $("input[name*=event]:checked").length);
    $("#qty-selected").data("attendance", 0);

    $("input[name*=event]:checked").each(function () {
      $("#qty-selected").data(
        "attendance",
        $("#qty-selected").data("attendance") + $(this).data("attendance")
      );
    });

    if ($("#stats").data("checked")) {
      $("#qty-selected").html(
        " " +
          $("#stats").data("checked") +
          " events selected " +
          $.number($("#qty-selected").data("attendance"), 0, ".", ",") +
          " total attendees"
      );
    } else {
      $("#qty-selected").html("None selected");
    }
  }
});
