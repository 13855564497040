$(document).ready(function () {
  if ($('#donations-index').length > 0) {
    $('select[name=event]').change(function () {
        $('#submit').click()
      })

    $('select[name=sort]').change(function () {
          $('#submit').click()
        })
  }
  if ($('#donations-show').length > 0) {
    $('select[name=event]').change(function () {
        $('#submit').click()
      })
  }
  if ($('#donations-show').length > 0) {
    $('input[name=ship_to_zip_code]').removeClass('hidden')

    $('#event_date').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
      startDate: moment(new Date()).add($('input[name="lead_time"]').val(), 'days'),
      locale: {
        cancelLabel: 'Clear',
        format: 'MM/DD/YYYY'
      },
      isInvalidDate: function (date) {
        var instructions = $('[id^=instructions_]').not('.hidden')

        var holidayLocale = instructions.data('holiday-locale')

        moment.modifyHolidays.set(holidayLocale || 'United States')
        return $('input[name="lead_time"]').data('days_of_week')[date.isoWeekday() == 7 ? 0 : date.isoWeekday()] === undefined || date.isBefore(moment(new Date()).add($('input[name="lead_time"]').val(), 'days')) || date.isHoliday()
      }
    }, function (chosen_date) {
      $('#event_date').val(chosen_date.format('MM/DD/YYYY'))
    })

    $('#event_date').focusout(function () {
      $('#event_date').val($('#event_date').val().replace(/\/00/g, '/20'))
    })

    $('#event_date').on('apply.daterangepicker', function () {
      $('#event_date').val($('#event_date').val().replace(/\/00/g, '/20'))
    })

    $('[name=delivery_method]').on('change', function () {
      displayLocation()
    })

    $("select[name='location_id']").select2({
      width: 'resolve',
      multiple: false,
      closeOnSelect: true,
      dropdownAutoWidth: true
    })

    $("select[name='event']").select2({
      width: 'resolve',
      multiple: false,
      closeOnSelect: true,
      dropdownAutoWidth: true
    })

    $("select[name='location_id']").change(function () {
      var location = $("select[name='location_id']").val()
      $('[id^=instructions_]').addClass('hidden')
      $('#instructions_' + location).removeClass('hidden')
    })

    displayLocation()

    tinymce.init({
      selector: '#event-purpose',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1:
        'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px'
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })
  }
  function displayLocation () {
    $('#locations').addClass('hidden')
    switch ($('[name=delivery_method]').val()) {
      case 'pickup' :
        $('#locations').removeClass('hidden')
        break
      case 'giveback' :
        $('#locations').removeClass('hidden')
        break
    }
  }
})
