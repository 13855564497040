$(document).ready(function () {

    if ($("#verifyRedeemCertificateModal").length > 0) {

        $('#verifyRedeemCertificateModal').on('keyup keypress', function(e) {
            var code = e.keyCode || e.which;
            if (code == 13) {
                e.preventDefault();
                return false;
            }
        });

        var table = $('#certificate-index-datatables').DataTable({
            "sDom": "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
            stateSave: true,
            fixedHeader: true,
            lengthMenu: [[25, 50, 100, -1], [25, 50, 100, "All"]],
            "language": {
                "zeroRecords": "Sorry, there were no results for the selected criteria",
                "infoEmpty": "0 Results",
                "infoFiltered": "(filtered from _MAX_ total results)",
                "search": '<i class="im-search"> </i>',
                "lengthMenu": "<span>_MENU_</span>",
                "searchPlaceholder": 'Search...',
                "info": "Showing _START_ to _END_ of _TOTAL_ results"
            },
            "fnDrawCallback":function(){
                if ( $(".paginate_button.active").length > 0) {
                    $('.dataTables_paginate.paging_simple_numbers')[0].style.display = "block";
                } else {
                    $('.dataTables_paginate.paging_simple_numbers')[0].style.display = "none";
                }
            }
        });

        $(".row-count-pre").html("Show");
        $(".row-count-post").html("per page");


        $("#closeRedeemCertificate").on('click', function() {
            location.reload();
        });

        $(".redeem-certificate").on('click', function() {

            $("#redeemCertificate").val($(".redeem-certificate").data('id'));
            $("#redeemCertificate").removeClass("hidden");
            $("#checkCertificate").addClass("hidden");
            $("#verifyRedeemCertificateModal [name=code]").val($(".redeem-certificate").data('code'));
        });

        $("#checkCertificate").on('click', function () {

            $("#redeemSuccess").addClass("hidden");
            $("#verifySuccess").addClass("hidden");
            $("#redeemError").addClass("hidden");
            $("#verifyError").addClass("hidden");
            $("#certificateInformation").addClass("hidden");
            $("#certificateInformation").html('');

            $.ajax({
                dataType: "json",
                url: '/certificates/verify/' + $("input[name=code]").val(),
                data: $('#certificate_redeem').serialize(),
                success: function (data, textStatus, jqXHR) {

                    var parsed_data = JSON.parse(data);
                    var today = new Date();

                    if (parsed_data.id) {
                        if (parsed_data.match.expiration_date != null && parsed_data.match.expiration_date != '') {
                            var expirationDate = new Date(parsed_data.match.expiration_date.slice(0,4), parsed_data.match.expiration_date.slice(5,7) - 1, parsed_data.match.expiration_date.slice(8,10));
                            if (today > expirationDate) {
                                $("#certificateInformation").html('<span style="color:red;">This certificate has expired</span><br>Issued to: <strong>' + parsed_data.match.audience_name + '</strong><br> Expired: <strong>' + (expirationDate.getMonth() + 1) + '/' +
                                    expirationDate.getDate() + '/' + expirationDate.getFullYear() + '</strong><br> Value: $' + parsed_data.match.value);
                            }
                            else {
                                $("#certificateInformation").html('Issued to: <strong>' + parsed_data.match.audience_name + '</strong><br> Expires: ' + (expirationDate.getMonth() + 1) + '/' + expirationDate.getDate() + '/' + expirationDate.getFullYear() + '<br> Value: $' + parsed_data.match.value);
                            }
                        }
                            $("#verifyError").addClass("hidden");
                            $("#redeemCertificate").val(parsed_data.id);
                            $("#redeemCertificate").removeClass("hidden");
                            $("#verifySuccess").removeClass("hidden");
                            $("#certificateInformation").removeClass("hidden");
                    }
                    else {
                        $("#redeemCertificate").addClass("hidden");
                        $("#verifyError").removeClass("hidden");
                        $("#verifySuccess").addClass("hidden");
                    }
                }
            });

        });

        $("#redeemCertificate").on('click', function () {

            $("#redeemSuccess").addClass("hidden");
            $("#verifySuccess").addClass("hidden");
            $("#redeemError").addClass("hidden");
            $("#verifyError").addClass("hidden");
            $("#certificateInformation").addClass("hidden");
            $("#certificateInformation").html('');

            $.ajax({
                dataType: "json",
                url: '/certificates/' + $("#redeemCertificate").val() + '/redeem',
                data: $('#certificate_redeem').serialize(),
                success: function (data, textStatus, jqXHR) {
                    var parsed_data = JSON.parse(data);
                       $("#checkCertificate").removeClass("hidden");
                    if (parsed_data.success) {
                        $("#redeemError").addClass("hidden");
                        $("#redeemCertificate").addClass("hidden");
                        $("#redeemSuccess").removeClass("hidden");
                        $("#verifySuccess").addClass("hidden");
                    }
                    else {
                        $("#redeemError").removeClass("hidden");
                        $("#redeemSuccess").addClass("hidden");
                    }
                }
            });
        });
    }
});