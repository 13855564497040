/* eslint-disable no-undef */
$(document).ready(function () {
  if ($('#match-nonprofit-show').length > 0) {
    tinymce.init({
      selector: '.html-textarea',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1: 'undo redo pastetext| bold italic',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px';
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })

    $("select[name='location_id']").select2({
      width: 'resolve',
      multiple: false,
      closeOnSelect: true,
      dropdownAutoWidth: true
    })

    $('[name=delivery_method]').on('change', function () {
      $('#locations').hide()
      switch ($('[name=delivery_method]').val()) {
        case 'pickup':
          $('#locations').show()
          break
      }
    })

    $('i.fa.fa-star-o')
      .trigger('mouseenter')
      .trigger('mouseleave')

    $('.rating').on('change', function () {
      match_rate($(this).data('id'), $(this).val())
    })

    $('#update-recipient_workflow-status #update_workflow').click(function () {
      $('input#update_recipient_workflow_status').click()
    })

    $('#declineOfferModal #decline_offer').click(function () {
      $('input#decline').click()
    })

    $('#approve_static_pdf_offer-form #approve_offer').click(function () {
      $('input#approve_static_pdf_offer').click()
    })

    $('#approve_auto_offer-form #approve_offer').click(function () {
      $('input#approve_auto_offer').click()
    })

    $('#approve_auto_offer-form #approve_offer').click(function () {
      $('input#approve_auto_offer').click()
    })

    $('#approve_ship_offer-form #approve_offer').click(function () {
      $('input#approve_ship_offer').click()
    })

    $('#approve_pickup_offer-form #approve_offer').click(function () {
      $('input#approve_pickup_offer').click()
    })

    $('#approve_service_offer-form #approve_offer').click(function () {
      $('input#approve_service_offer').click()
    })

    $('#approve_pickup_preferred_offer-form #approve_offer').click(function () {
      $('input#approve_pickup_preferred_offer').click()
    })

    $('#approve_ship_preferred_offer-form #approve_offer').click(function () {
      $('input#approve_ship_preferred_offer').click()
    })

    $('#cancel-request-form #cancel_request').click(function () {
      $('input#cancel-request').click()
    })
  }

  if ($('#match-company-show').length > 0) {
    tinymce.init({
      selector: '.html-textarea',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      paste_as_text: true,
      menubar: false,
      toolbar1: 'undo redo pastetext| bold italic',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px';
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })

    $('input[name=event_date]').daterangepicker({
      showDropdowns: true,
      timePicker: true,
      timePickerIncrement: 30,
      locale: {
        format: 'MM/DD/YYYY h:mm A'
      },
      isInvalidDate: function (date) {
        var holidayLocale = 'United States';

        var el = $("input[name='holiday_locale']")

        if (el) {
          holidayLocale = el.val()
        }

        moment.modifyHolidays.set(holidayLocale || 'United States')
        return (
          date.isoWeekday() === 6 ||
          date.isoWeekday() === 7 ||
          date.isBefore(
            moment(new Date()).add($('input[name="lead_time"]').val(), 'days')
          ) ||
          date.isHoliday()
        )
      }
    })

    $('#declineRequestModal input#decline:radio').on('ifChecked', function () {
      $('#decline_option').html(
        'Are you sure you want to decline this request?'
      )
      $('#swap_option').addClass('hidden')
    })

    $('#declineRequestModal input.swap:radio').on('ifChecked', function () {
      $('#decline_option').html('')
      $('#swap_option').removeClass('hidden')
      $('#swap_option input[name=quantity]').val(
        $(this).attr('data-max-quantity')
      )
    })

    $('i.fa.fa-star-o')
      .trigger('mouseenter')
      .trigger('mouseleave')

    $('.rating').on('change', function () {
      match_rate($(this).data('id'), $(this).val())
    })

    $('#declineRequestModal #decline_request').click(function () {
      $('input#decline').click()
    })

    $('#update-provider_workflow-status #update_workflow').click(function () {
      $('input#update_provider_workflow_status').click()
    })

    $('#approve-auto-request #approve_request').click(function () {
      $('input#approve_auto_request').click()
    })

    $('#approve-placeholder-request #approve_request').click(function () {
      $('input#approve_placeholder_request').click()
    })

    $('#approve-giveback-request #approve_request').click(function () {
      $('input#approve_giveback_request').click()
    })

    $('#approve-static-pdf-form #approve_request').click(function () {
      $('input#approve_static_pdf_request').click()
    })

    $('#upload-static-pdf-form #approve_request').click(function () {
      $('input#upload_static_pdf').click()
    })

    $('#approve_pickup_request-form #approve_request').click(function () {
      $('input#approve_pickup_request').click()
    })

    $('#approve_ship_request-form #approve_request').click(function () {
      $('input#approve_ship_request').click()
    })

    $('#approve_service_request-form #approve_request').click(function () {
      $('input#approve_service_request').click()
    })

    $('#cancel-offer-form #cancel_offer').click(function () {
      $('input#cancel-offer').click()
    })
  }

  if ($('#company-matches-index').length > 0) {
    $('#event_clear_begin_date').click(function () {
      $('input[name=event_begin_date]').val('')
    })

    $('#event_clear_end_date').click(function () {
      $('input[name=event_end_date]').val('')
    })

    $('input[name=on_site_access]').onoff()

    $('#matchFilterModal').on('show.bs.modal', function () {
      $.fn.modal.Constructor.prototype.enforceFocus = function () {}
    })

    $('input[name=event_begin_date], input[name=event_end_date]').on(
      'click',
      function (event) {
        $('input#date_type_days').iCheck('uncheck')
        $('input#date_type_range').iCheck('check')
      }
    )

    $('input[name=delta]').on('click', function (event) {
      $('input#date_type_days').iCheck('check')
      $('input#date_type_range').iCheck('uncheck')
    })

    var eventBeginDate = $('input[name=event_begin_date]').val()

    $('input[name=event_begin_date]').daterangepicker({
      showDropdowns: true,
      singleDatePicker: true,
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    if (eventBeginDate == '') {
      $('input[name=event_begin_date]').val('')
    }

    var eventEndDate = $('input[name=event_end_date]').val()

    $('input[name=event_end_date]').daterangepicker({
      showDropdowns: true,
      singleDatePicker: true,
      locale: {
        format: 'MM/DD/YYYY'
      }
    })

    if (eventEndDate == '') {
      $('input[name=event_end_date]').val('')
    }

    if (!$('#match-company-index li').hasClass('active')) {
      $('#match-company-index li')
        .first()
        .addClass('active')
    }

    $('i.fa.fa-star-o')
      .trigger('mouseenter')
      .trigger('mouseleave')

    $('.rating').on('change', function () {
      match_rate($(this).data('id'), $(this).val())
    })

    $('input[name=delta]').keypress(function () {
      $('input[name=event_begin_date]').val('')
      $('input[name=event_end_date]').val('')
    })

    $('input[name=event_begin_date]').on('apply.daterangepicker', function () {
      $('input[name=delta]').val('')
    })

    $('input[name=event_end_date]').on('apply.daterangepicker', function () {
      $('input[name=delta]').val('')
    })

    $('.approve_request').on('click', function () {
      var modal = $(
        '#approve' + $(this).data('delivery-method') + 'RequestModal'
      )
      $('#approve-' + $(this).data('delivery-method') + '-request').attr(
        'action',
        $(location).attr('href') + '/' + $(this).data('match-id')
      )
      modal.data('default-quantity', $(this).data('quantity'))
      modal.find('input[name=quantity]').val($(this).data('quantity'))
      modal
        .find('input[name=quantity]')
        .data('default-quantity', $(this).data('quantity'))
      modal.find('input[name=quantity]').data('title', $(this).data('title'))

      if ($(this).data('require_redemption_code') == '1') {
        $('span#redemption_code').text(
          'You currently have ' +
            $(this).data('available_redemption_code_count') +
            ' preloaded redemption codes.'
        )
        $('.require_redemption_code').removeClass('hidden')
      }
 else {
        $('#require_redemption_code').addClass('hidden')
      }
      modal.modal('show')
    })

    $('input[name=select_all]').on('click', function () {
      $("input[name*='match']").prop('checked', $(this).is(':checked'))
      update_stats()
    })

    $('input[name*=match]').on('click', function () {
      update_stats()
    })

    var table = $('#company-matches-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, 250, 500, -1],
        [25, 50, 100, 250, 500, 'All']
      ],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search" style="float: left; padding: 5px;"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search Within Results',
        info:
          parseInt($('#match-company-index').data('total'), 10) >
          parseInt($('#match-company-index').data('count'), 10)
            ? 'Showing _START_ to _END_ of first _TOTAL_ results (' +
              $('#match-company-index').data('total') +
              ' total)'
            : 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      columns: [
        { orderable: false },
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ],
      order: [[5, 'asc']],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block';
        }
 else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none';
        }
        update_stats()
      }
    })

    $('div.dataTables_filter').appendTo($('.dt_filter'))
    $('.dt_filter input').attr('style', 'width: 80% !important')

    $('.row-count-pre').html('Show')
    $('.row-count-post').html('per page')

    $('#action').insertBefore(
      $('.table-search:eq(0)', table.table().container())
    )
    $('#stats').removeClass('hidden')

    $('#bulk-decline').on('click', function () {
      $('#decline_request').removeClass('hidden')
      $('#decline-note').removeClass('hidden')
      if ($('#stats').data('checked') == 0) {
        $('#bulk-decline-message').html(
          'You have not selected a match to decline.'
        )
        $('#decline_request').addClass('hidden')
        $('#decline-note').addClass('hidden')
      }
 else if ($('#stats').data('checked') == 1) {
        $('#bulk-decline-message').html(
          'Are you sure you want to decline the selected request?'
        )
      }
 else {
        $('#bulk-decline-message').html(
          'Are you sure you want to decline these ' +
            $('#stats').data('checked') +
            ' requests?'
        )
      }
    })

    $('#decline_request').on('click', function () {
      $('#bulk-decline-request').click()
    })

    $('#bulk-approve').on('click', function () {
      $('#approve_request').removeClass('hidden')
      if ($('#stats').data('checked') == 0) {
        $('#bulk-approve-message').html(
          'You have not selected any matches to approve.'
        )
        $('#approve_request').addClass('hidden')
      }
 else if ($('#stats').data('checked') == 1) {
        $('#bulk-approve-message').html(
          'Are you sure you want to approve the selected request?'
        )
      }
 else {
        $('#bulk-approve-message').html(
          'Are you sure you want to approve these ' +
            $('#stats').data('checked') +
            ' requests?'
        )
      }
    })

    $('#bulk_export').on('click', function () {
      $('#bulk-export').click()
    })

    $('#bulk_update-workflow').on('click', function () {
      $('#bulk-update-workflow').click()
    })

    $('#approve_request').on('click', function () {
      $('#bulk-approve-request').click()
    })

    // Event Filters

    if (!$('input.country:checked').length) {
      $('input[name^=countries][data-country=usa]').iCheck('check')
    }

    $('input#location_type_metro_area:radio').on('ifChecked', function () {
      $('#zip_codes_group').addClass('hidden')
      $('#distance_group').addClass('hidden')
      $('div#metro_areas_group').removeClass('hidden')
      $('#metro_area_div').removeClass('hidden')
    })

    $('input#location_type_metro_area:radio').on('ifUnchecked', function () {
      $('#zip_codes_group').removeClass('hidden')
      $('#distance_group').removeClass('hidden')
      $('div#metro_areas_group').addClass('hidden')
      $('#metro_area_div').addClass('hidden')
    })

    $('input#location_type_zip_code:radio').on('ifChecked', function () {
      $('#zip_codes_group').removeClass('hidden')
      $('#distance_group').removeClass('hidden')
      $('#metro_area_div').addClass('hidden')
    })

    $('input#location_type_zip_code:radio').on('ifUnchecked', function () {
      $('#zip_codes_group').addClass('hidden')
      $('#distance_group').addClass('hidden')
      $('#metro_area_div').removeClass('hidden')
    })

    var placeholder = getItemMetroAreaPlaceHolder()

    $('.metro-area-multiple').select2({
      placeholder: placeholder,
      allowClear: true,
      width: '100%',
      multiple: true,
      closeOnSelect: false,
      dropdownAutoWidth: true
    })

    if ($('#item_uses input:checkbox:checked').length == 0) {
      $('#item_uses input:checkbox').prop('checked', true)
    }

    if ($('#venue_type input:checkbox:checked').length == 0) {
      $('#venue_type input:checkbox').prop('checked', true)
    }

    if ($('#reach_methods input:checkbox:checked').length == 0) {
      $('#reach_methods input:checkbox').prop('checked', true)
    }

    if ($('#gender_ratio input:checkbox:checked').length == 0) {
      $('#gender_ratio input:checkbox').prop('checked', true)
    }

    if ($('#audience_other input:checkbox:checked').length == 0) {
      $('#audience_other input:checkbox').prop('checked', true)
    }

    if ($('#recipient_age_ranges input:checkbox:checked').length == 0) {
      $('#recipient_age_ranges input:checkbox').prop('checked', true)
    }

    if (
      $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
        .length == 0
    ) {
      $('#children_age_ranges input:checkbox.doesntMatter').prop(
        'checked',
        true
      )
    }

    if ($('#nonprofit_types input:checkbox:checked').length == 0) {
      $('#nonprofit_types input:checkbox').prop('checked', true)
    }

    if ($('#nonprofit_tax_statuses input:checkbox:checked').length == 0) {
      $('#nonprofit_tax_statuses input:checkbox').prop('checked', true)
    }

    if ($('#nonprofit_categories input:checkbox:checked').length == 0) {
      $('#nonprofit_categories input:checkbox').prop('checked', true)
    }

    if ($('#nonprofit_designations input:checkbox:checked').length == 0) {
      $('#nonprofit_designations input:checkbox').prop('checked', true)
    }

    $('input[name=school_filter]').on('change', function () {
      if ($('input[name=school_filter]').prop('checked')) {
        $('#school_filter').addClass('hidden')
      }
 else {
        $('#school_filter').removeClass('hidden')
      }
    })

    $("#children_age_ranges input:checkbox:not('.doesntMatter')").on(
      'click',
      function () {
        if ($(this).prop('checked')) {
          $('#children_age_ranges .doesntMatter').prop('checked', false)
          if (
            $(
              "#children_age_ranges input:checkbox:not(:checked):not('.doesntMatter')"
            ).length == 0
          ) {
            $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop(
              'checked',
              false
            )
            $('#children_age_ranges input:checkbox.doesntMatter').prop(
              'checked',
              true
            )
          }
        }
 else if (
          $("#children_age_ranges input:checkbox:checked:not('.doesntMatter')")
            .length == 0
        ) {
          $('#children_age_ranges .doesntMatter').prop('checked', true)
        }
      }
    )

    $('#children_age_ranges input:checkbox.doesntMatter').on(
      'click',
      function () {
        if ($(this).prop('checked')) {
          $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop(
            'checked',
            false
          )
        }
      }
    )
  }
  if ($('#nonprofit-matches-index').length > 0) {
    if (!$('#match-nonprofit-index li').hasClass('active')) {
      $('#match-nonprofit-index li')
        .first()
        .addClass('active')
    }

    $('i.fa.fa-star-o')
      .trigger('mouseenter')
      .trigger('mouseleave')

    $('.rating').on('change', function () {
      match_rate($(this).data('id'), $(this).val())
    })

    var table = $('#nonprofit-matches-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search Within Results',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      order: [[0, 'desc']],
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block';
        }
 else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none';
        }
      }
    })

    $('div.dataTables_filter').appendTo($('.dt_filter'))

    $('.auto-update').on('change', function () {
      $('#submit').click()
    })

    $('input[name=unread]').on('ifChanged', function () {
      $('#submit').click()
    })

    $('.row-count-pre').html('Show')
    $('.row-count-post').html('per page')
  }

  function update_stats () {
    $('[name=select_all]').prop(
      'checked',
      $('input[name*=match]').length === $('input[name*=match]:checked').length
    )
    $('#stats').data('checked', $('input[name*=match]:checked').length)
    $('#bulk-approve').removeClass('disabled')
    $('#has_redemption_codes').addClass('hidden')
    $('#qty-selected').data('attendance', 0)

    var items = []

    $('input[name*=match]:checked').each(function () {
      items[$(this).data('item-id')] =
        (items[$(this).data('item-id')] ? items[$(this).data('item-id')] : 0) +
        Number($(this).data('qty'))
      if (
        (items[$(this).data('item-id')] >
          Number($(this).data('redemption-codes-available')) ||
          Number($(this).data('redemption-codes-available')) == 0) &&
        $(this).hasClass('redemptionCode')
      ) {
        $('#bulk-approve').addClass('disabled')
        $('#has_redemption_codes').removeClass('hidden')
      }
      $('#qty-selected').data(
        'attendance',
        $('#qty-selected').data('attendance') + $(this).data('attendance')
      )
    })

    if ($('#stats').data('checked')) {
      $('#qty-selected').html(
        ' ' +
          $('#stats').data('checked') +
          ' events selected ' +
          $.number($('#qty-selected').data('attendance'), 0, '.', ',') +
          ' total attendees'
      )
    }
 else {
      $('#qty-selected').html('None selected')
    }
  }

  function match_rate (match_id, rating) {
    var data = JSON.stringify({
      rating: rating
    })

    $.ajax({
      url: '/matches/' + match_id + '/rate',
      dataType: 'json',
      contentType: 'application/json; charset=UTF-8',
      data: data,
      type: 'POST',
      error: function (xhr, ajaxOptions, thrownError) {
        return true
      }
    })
  }
})
