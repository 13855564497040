$(document).ready(function () {

    if ($("#campaign-form").length > 0) {

        campaignSetItemUses();

        $('input[name=status]').onoff();

        $("input[name=school_filter]").on("change", function() {
            if($("input[name=school_filter]").prop('checked')) {
                $("#school_filter").addClass('hidden');
            } else {
                $("#school_filter").removeClass('hidden');
            }
        });

        if ($("#children_age_ranges input:checkbox:checked:not('.doesntMatter')").length == 0) {
            $("#children_age_ranges input:checkbox.doesntMatter").prop('checked', true);
        }

        $("#children_age_ranges input:checkbox:not('.doesntMatter')").on("click", function() {
            if($(this).prop('checked')) {
                $("#children_age_ranges .doesntMatter").prop('checked', false);
                if ($("#children_age_ranges input:checkbox:not(:checked):not('.doesntMatter')").length == 0) {
                    $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop('checked', false);
                    $("#children_age_ranges input:checkbox.doesntMatter").prop('checked', true);
                }
            } else if ($("#children_age_ranges input:checkbox:checked:not('.doesntMatter')").length == 0) {
                $("#children_age_ranges .doesntMatter").prop('checked', true);
            }
        });

        $("#children_age_ranges input:checkbox.doesntMatter").on("click", function() {
            if($(this).prop('checked')) {
                $("#children_age_ranges input:checkbox:not('.doesntMatter')").prop('checked', false);
            }
        });



        if ($("#item_uses input:checkbox:checked").length == 0) {
            $("#item_uses input:checkbox").prop('checked', true);
        }

        if ($("#venue_type input:checkbox:checked").length == 0) {
            $("#venue_type input:checkbox").prop('checked', true);
        }

        if ($("#reach_methods input:checkbox:checked").length == 0) {
            $("#reach_methods input:checkbox").prop('checked', true);
        }

        if ($("#gender_ratio input:checkbox:checked").length == 0) {
            $("#gender_ratio input:checkbox").prop('checked', true);
        }

        if ($("#audience_other input:checkbox:checked").length == 0) {
            $("#audience_other input:checkbox").prop('checked', true);
        }

        if ($("#recipient_age_ranges input:checkbox:checked").length == 0) {
            $("#recipient_age_ranges input:checkbox").prop('checked', true);
        }

        if ($("#children_age_ranges input:checkbox:checked:not('.doesntMatter')").length == 0) {
            $("#children_age_ranges input:checkbox.doesntMatter").prop('checked', true);
        }

        if ($("#nonprofit_types input:checkbox:checked").length == 0) {
            $("#nonprofit_types input:checkbox").prop('checked', true);
        }

        if ($("#nonprofit_tax_statuses input:checkbox:checked").length == 0) {
            $("#nonprofit_tax_statuses input:checkbox").prop('checked', true);
        }

        if ($("#nonprofit_categories input:checkbox:checked").length == 0) {
            $("#nonprofit_categories input:checkbox").prop('checked', true);
        }

        if ($("#nonprofit_designations input:checkbox:checked").length == 0) {
            $("#nonprofit_designations input:checkbox").prop('checked', true);
        }

        $("input[name=type\\[\\]]").on('ifClicked', function (e) {
            if ($(this).val() != 'campaign_action_code_notify' && $(this).data('subscription') == 'noSubscription') {
                $('input[value=campaign_action_code_notify]').iCheck('check');
                $("#missingSubscriptionModal").modal("show");
            }
        });

        $("input[name=type\\[\\]]").on('ifChecked', function (e) {
            if ($(this).val() == 'campaign_action_code_notify') {
                $('.not-notify').addClass('hidden');
                $(".wstep.last-child").html('<div class="donut"><i class="fa-save s24"></i></div><span class="txt">Save</span>');
            }
            else if ($(this).data('subscription') == 'subscription'){
                $('.not-notify').removeClass('hidden');
                $(".wstep.last-child").html('<div class="donut"><i class="fa-list-ol s24"></i></div><span class="txt">Prioritization</span>');
            }
        });

        $('#missingSubscriptionModal').on('hidden.bs.modal', function () {
            $('input[value=campaign_action_code_notify]').iCheck('check');
    //        $("#campaign-create-wizard").formwizard("update_steps");
        });

        var nextRunDate = $('input[name=next_run_date]').val();

        $('input[name=next_run_date]').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            changeYear: true,
            "minDate": "01/01/2015",
            locale: {
                format: 'MM/DD/YYYY',
            }
        });

        if (nextRunDate == '') {
            $('input[name=next_run_date]').val('');
        } else {
            $('input[name=next_run_date]').val(displayDateFormat(nextRunDate))
        }

        var beginDate = $('input[name=begin_date]').val();

        $('input[name=begin_date]').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            changeYear: true,
            "minDate": "01/01/2015",
            locale: {
                format: 'MM/DD/YYYY',
            }
        });

        if (beginDate == '') {
            $('input[name=begin_date]').val('');
        } else {
            $('input[name=begin_date]').val(displayDateFormat(beginDate))
        }

        $("#clear_begin_date").click(function () {
            $("input[name=begin_date]").val("");
        });

        var endDate = $('input[name=end_date]').val();

        $('input[name=end_date]').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            changeYear: true,
            "minDate": "01/01/2015",
            locale: {
                format: 'MM/DD/YYYY',
            }
        });

        if (endDate == '') {
            $('input[name=end_date]').val('');
        } else {
            $('input[name=end_date]').val(displayDateFormat(endDate))
        }

        $("#clear_end_date").click(function () {
            $("input[name=end_date]").val("");
        });


        $("input#location_type_metro_area:radio").on('ifChecked', function () {
            $("#zip_codes_group").addClass('hidden');
            $("#distance_group").addClass('hidden');
            $("#metro_areas_group").removeClass('hidden');
        });

        $("input#location_type_metro_area:radio").on('ifUnchecked', function () {
            $("#zip_codes_group").removeClass('hidden');
            $("#distance_group").removeClass('hidden');
            $("#metro_areas_group").addClass('hidden');
        });

        $("input#location_type_zip_code:radio").on('ifChecked', function () {
            $("#zip_codes_group").removeClass('hidden');
            $("#distance_group").removeClass('hidden');
            $("#metro_areas_group").addClass('hidden');
        });

        $("input#location_type_zip_code:radio").on('ifUnchecked', function () {
            $("#zip_codes_group").addClass('hidden');
            $("#distance_group").addClass('hidden');
            $("#metro_areas_group").removeClass('hidden');
        });

        $(".metro-area-multiple").select2({
            placeholder: "All Metro Areas",
            allowClear: true,
            width: 'resolve',
            multiple: true,
            closeOnSelect: false,
            dropdownAutoWidth: true
        });

        $("select[name=exclude_tags\\[\\]]").select2({
            placeholder: "Select Criteria",
            allowClear: true,
            width: 'resolve',
            multiple: true,
            closeOnSelect: false
        });

        $(".approve").click(function () {
            reset_campaign_checkboxes();
            $("input[name=action]").val('approve');
            $("#campaign-edit-form").submit();
        });

        $(".save").click(function () {
            reset_campaign_checkboxes();
            $("input[name=action]").val('save');
            $("#campaign-edit-form").submit();
        });

        $("#campaign-create-wizard").formwizard({
            formPluginEnabled: false,
            validationEnabled: false,
            focusFirstInput: true,
            textNext: 'Next >',
            textBack: '< Back',
            textSubmit: 'Save',
            submitStepClass: 'submit-wizard',
            formOptions: {
                beforeSubmit: function(data) {
                    $("input:disabled").removeAttr('disabled');
                    $("select:disabled").removeAttr('disabled');
                    $("textarea:disabled").removeAttr('disabled');
                    $("input[name=action]").val('save');
                },
                success: function(data) {
                },
                resetForm: false
            },
            disableUIStyles: true,
            showSteps: true //show the step
        });

        $("#campaign-create-wizard").bind("before_step_shown", function(event, data){
            if (data.isLastStep) {
                if ($("input[name=type\\[\\]]")[0].checked || $("input[name=is_approved]").val()) {
                    $("#save").val('Save');
                } else {
                    $("#save").val('Submit for Approval');
                }
            }
        });

        $(".wstep").click(function() {
            $(".wizard-actions").addClass("hidden");
            var desiredStep = $(this).data('step-num') + 1;
            var currentStep = $(".wstep.current").data('step-num');
            $("#campaign-create-wizard").formwizard("show",$(".step[data-step-num='" + desiredStep + "']").attr('id'));
            $(".wizard-actions").removeClass("hidden");
        });

        $(".priority").on('change', function() {

            switch ($(this).val()) {
                case "attendance" :
                    $('#preferred_gender[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_venue_type[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_metro_area[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_audience_other[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    break;

                case "gender_ratio" :
                    $('#preferred_gender[data-index="' + $(this).data('index')  + '"]').removeClass("hidden");
                    $('#preferred_venue_type[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_metro_area[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_audience_other[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    break;

                case "venue_type" :
                    $('#preferred_venue_type[data-index="' + $(this).data('index')  + '"]').removeClass("hidden");
                    $('#preferred_gender[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_metro_area[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_audience_other[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    break;

                case "audience_other" :
                    $('#preferred_audience_other[data-index="' + $(this).data('index')  + '"]').removeClass("hidden");
                    $('#preferred_venue_type[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_gender[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_metro_area[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    break;

                case "metro_area" :
                    $('#preferred_metro_area[data-index="' + $(this).data('index')  + '"]').removeClass("hidden");
                    $('#preferred_audience_other[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_venue_type[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_gender[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    break;

                default :
                    $('#preferred_metro_area[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_audience_other[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_venue_type[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    $('#preferred_gender[data-index="' + $(this).data('index')  + '"]').addClass("hidden");
                    break;

            }

        });

    }
});

function reset_campaign_checkboxes() {

    if ($("#item_uses input:checkbox:not(:checked)").length == 0) {
        $("#item_uses input:checkbox").prop('checked', false);
    }

    if ($("#venue_type input:checkbox:not(:checked)").length == 0) {
        $("#venue_type input:checkbox").prop('checked', false);
    }

    if ($("#reach_methods input:checkbox:not(:checked)").length == 0) {
        $("#reach_methods input:checkbox").prop('checked', false);
    }

    if ($("#gender_ratio input:checkbox:not(:checked)").length == 0) {
        $("#gender_ratio input:checkbox").prop('checked', false);
    }

    if ($("#audience_other input:checkbox:not(:checked)").length == 0) {
        $("#audience_other input:checkbox").prop('checked', false);
    }

    if ($("#recipient_age_ranges input:checkbox:not(:checked)").length == 0) {
        $("#recipient_age_ranges input:checkbox").prop('checked', false);
    }

    if ($("#children_age_ranges input:checkbox:not(:checked)").length == 0) {
        $("#children_age_ranges input:checkbox").prop('checked', false);
    }

    if ($("#nonprofit_types input:checkbox:not(:checked)").length == 0) {
        $("#nonprofit_types input:checkbox").prop('checked', false);
    }

    if ($("#nonprofit_tax_statuses input:checkbox:not(:checked)").length == 0) {
        $("#nonprofit_tax_statuses input:checkbox").prop('checked', false);
    }
}

function campaignSetItemUses(action) {

    if ($("#delivery_method").val() == 'service' || action == 'checked' || $("#campaign-form input[name=is_sample]").val() == true) {
        $("#item_uses").addClass('hidden');
        $(".sample").addClass('hidden');
    } else {
        $("#item_uses").removeClass('hidden');
        $(".sample").removeClass('hidden');
    }

};