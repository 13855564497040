$(document).ready(function () {
  if ($('#email-template-create-or-edit').length > 0) {
    tinymce.init({
      selector: '#edit-body',
      theme: 'modern',
      fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt',
      plugins: [
        'code',
        'advlist autolink lists link image charmap print preview hr anchor',
        'searchreplace wordcount visualblocks visualchars code fullscreen',
        'insertdatetime nonbreaking save table contextmenu directionality',
        'emoticons template paste textcolor colorpicker textpattern imagetools placeholder'
      ],
      menubar: false,
      paste_as_text: true,
      toolbar1:
        'pastetext | fontselect | fontsizeselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code',
      image_advtab: true,
      setup: function (ed) {
        ed.on('init', function () {
          this.getDoc().body.style.fontSize = '14px';
          this.getDoc().body.style.color = $('textarea').css('color')
        })
      }
    })
  }

  if ($('#emails-datatables').length > 0) {
    var table = $('#emails-datatables').DataTable({
      sDom:
        "<'row'<'table-search col-md-2 col-xs-6'f><'row-header pull-right'<'row-info-header col-md-12 col-xs-12'i><'row-count-header'<'row-count-pre'><'row-count'l><'row-count-post'>>>r>t<'row'<'col-md-4 col-xs-12'i><'col-md-8 col-xs-12'p><'col-md-12 col-xs-12'<'row-count-pre'><'row-count'l><'row-count-post'>>>",
      stateSave: true,
      fixedHeader: true,
      lengthMenu: [
        [25, 50, 100, -1],
        [25, 50, 100, 'All']
      ],
      language: {
        zeroRecords: 'Sorry, there were no results for the selected criteria',
        infoEmpty: '0 Results',
        infoFiltered: '(filtered from _MAX_ total results)',
        search: '<i class="im-search"> </i>',
        lengthMenu: '<span>_MENU_</span>',
        searchPlaceholder: 'Search...',
        info: 'Showing _START_ to _END_ of _TOTAL_ results'
      },
      fnDrawCallback: function () {
        if ($('.paginate_button.active').length > 0) {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'block';
        }
 else {
          $('.dataTables_paginate.paging_simple_numbers')[0].style.display =
            'none';
        }
      }
    })

    $('.row-count-pre').html('Show')
    $('.row-count-post').html('per page')
  }
})
