$(document).ready(function () {

    $("button[name=deletePaymentToken]").on('click', function () {
        $("#confirmDelete").data('token', $(this).data('token'))
    });

    $("#confirmDelete").on('click', function () {
        var token = $("#confirmDelete").data('token');
        $("input[value=" + token + "]").trigger('click');
    });

});
